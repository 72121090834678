import useLayoutContext from '@components/layout/useLayoutContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDown, faUp } from '@fortawesome/sharp-regular-svg-icons'
import {
  FeedItemInteraction,
  FeedItemNotification,
  FeedItemRedemptionSuggestion,
  FeedItemUnclaimedVendorSuggestion,
  FeedItemVendorSuggestion,
  FeedNotificationType,
  useFeedItemsQuery,
  useFeedSetGeoPointMutation,
} from '@graphql'
import useAnalytics from '@hooks/useAnalytics'
import { useGeolocation } from '@hooks/useGeolocation'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { ActionIcon, AppShell, Box, Flex, Stack } from '@mantine/core'
import { useIntersection } from '@mantine/hooks'
import useFeedStore from '@stores/useFeedStore'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FeedCard } from './components/FeedCard'
import { ClickInteractionContainer } from './components/interactions/Click.container'
import { PollInteractionContainer } from './components/interactions/Poll.container'
import { ReplyInteractionContainer } from './components/interactions/Reply.container'
import { NotificationFeedItem } from './components/notifications/NotificationFeedItem'
import { RedemptionSuggestion } from './components/suggestions/Redemption.suggestion'
import { UnclaimedSuggestion } from './components/suggestions/Unclaimed.suggestion'
import { VendorSuggestion } from './components/suggestions/Vendor.suggestion'

const ALL_CAUGHT_UP: FeedItemNotification = {
  __typename: 'FeedItemNotification',
  id: 'all-caught-up',
  addedAt: new Date().toISOString(),
  notification: {
    body: 'You’re all caught up!',
    title: 'All caught up',
    type: FeedNotificationType.Message,
  },
}

export function FeedPage() {
  const { setHeaderTransparent, setHeaderOpaque } = useLayoutContext()
  const [lastFeedItemId, setLastFeedItemId] = useState('')
  const containerRef = useRef<HTMLDivElement>(null)
  const isMobileViewport = useIsMobileViewport()
  const { lastSeenItemId, updateLastSeenItemId } = useFeedStore()
  const [initialAfterId, setInitialAfterId] = useState<string | undefined>()
  const analytics = useAnalytics()

  const { ref, entry: lastFeedItemEntry } = useIntersection({
    root: containerRef.current,
  })

  const handleUpdateLastSeenItemId = useCallback(
    (id: string) => {
      if (!id) return
      if (id === lastSeenItemId) return
      updateLastSeenItemId(id)
    },
    [lastSeenItemId, updateLastSeenItemId]
  )

  useEffect(
    function setInitialAfterIdOnLoad() {
      if (
        (lastSeenItemId && initialAfterId === undefined) ||
        initialAfterId === null
      ) {
        setInitialAfterId(lastSeenItemId)
      }
    },
    [initialAfterId, lastSeenItemId]
  )

  const { position, permissions } = useGeolocation()
  const [feedSetGeoPointMutation] = useFeedSetGeoPointMutation()

  useEffect(
    function setFeedGeoPoint() {
      if (permissions?.location === 'granted' && position) {
        feedSetGeoPointMutation({
          variables: {
            geoPoint: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          },
        })
      }
    },
    // We don't want to run this effect every time the position changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [permissions?.location]
  )

  const {
    data,
    loading,
    error,
    fetchMore: fetchMoreFeedItems,
  } = useFeedItemsQuery({
    variables: {
      afterId: initialAfterId,
      includeAfterItemInFeed: true,
    },
    skip: !initialAfterId,
    pollInterval: 0, // Override the default polling interval. We want to manually control this
  })

  const items = useMemo(
    function memoizedFeedItems() {
      return data?.feedItems
        ? [...(data?.feedItems || []).map((item) => item), ALL_CAUGHT_UP]
        : [ALL_CAUGHT_UP]
    },
    [data?.feedItems]
  )

  useEffect(
    function updateheaderTransparencyBasedOnViewport() {
      if (isMobileViewport) {
        setHeaderTransparent()
      } else {
        setHeaderOpaque()
      }

      return () => {
        setHeaderOpaque()
      }
    },
    [setHeaderTransparent, setHeaderOpaque, isMobileViewport]
  )

  const scrollToNextItem = (index: number) => {
    if (!items) return

    const nextFeedItemInteraction = items[index] as FeedItemInteraction

    const nextItem = document.getElementById(
      `${nextFeedItemInteraction.id}-${index}`
    )

    if (nextItem) {
      nextItem.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(
    function loadMoreFeedItems() {
      {
        if (lastFeedItemEntry?.isIntersecting) {
          fetchMoreFeedItems({
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev

              return {
                feedItems: [...prev.feedItems, ...fetchMoreResult.feedItems],
              }
            },
            variables: {
              afterId: lastFeedItemId,
              includeAfterItemInFeed: false,
            },
          })
        }
      }
    },
    [fetchMoreFeedItems, lastFeedItemEntry?.isIntersecting, lastFeedItemId]
  )

  useEffect(() => {
    const lastFeedItem = items[items.length - 2]
    setLastFeedItemId(lastFeedItem?.id)
  }, [items])

  if (loading) return <Box>Loading...</Box>

  if (error) return <Box>Error: {error.message}</Box>

  function handleScrollUp(id: string) {
    const item = document.getElementById(id)
    if (item) {
      item.scrollIntoView({ behavior: 'smooth' })
    }
  }

  function handleScrollDown(id: string) {
    const item = document.getElementById(id)
    if (item) {
      item.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <AppShell.Main pt={isMobileViewport ? 0 : undefined}>
      <Box
        ref={containerRef}
        h={
          isMobileViewport
            ? 'calc(100vh - var(--app-shell-footer-height))'
            : 'calc(100vh - var(--app-shell-header-height))'
        }
        style={{
          overflowY: 'scroll',
          scrollSnapType: 'y mandatory',
          scrollBehavior: 'smooth',
        }}
      >
        {items.map((item, idx: number) => {
          const isLastItem = idx === items.length - 2
          return (
            <React.Fragment key={`${item.id}-${idx}`}>
              <Flex
                h={isMobileViewport ? '100%' : '95%'}
                pt={isMobileViewport ? undefined : 20}
                w="100%"
                justify="center"
                gap={8}
              >
                <FeedCard
                  id={`${item.id}-${idx}`}
                  itemId={item.id}
                  ref={isLastItem ? ref : null}
                  handleUpdateLastSeenItemId={() =>
                    handleUpdateLastSeenItemId(item.id)
                  }
                >
                  {item.__typename === 'FeedItemInteraction' &&
                    item.interactions[0].__typename === 'InteractionPoll' && (
                      <PollInteractionContainer
                        id={item.interactions[0].id}
                        scrollToNextItem={() => scrollToNextItem(idx + 1)}
                      />
                    )}
                  {item.__typename === 'FeedItemInteraction' &&
                    item.interactions[0].__typename === 'InteractionClick' && (
                      <ClickInteractionContainer
                        id={item.interactions[0].id}
                        scrollToNextItem={() => scrollToNextItem(idx + 1)}
                      />
                    )}
                  {item.__typename === 'FeedItemInteraction' &&
                    item.interactions[0].__typename === 'InteractionReply' && (
                      <ReplyInteractionContainer
                        id={item.interactions[0].id}
                        scrollToNextItem={() => scrollToNextItem(idx + 1)}
                      />
                    )}
                  {item.__typename === 'FeedItemRedemptionSuggestion' && (
                    <RedemptionSuggestion
                      id={
                        (item as FeedItemRedemptionSuggestion)
                          .redemptionSuggestion.redemptionId
                      }
                      skip={() => {
                        scrollToNextItem(idx + 1)
                        analytics.feedItemAction({
                          itemId: item.id,
                          durationMs: 1,
                          type: 'redemption-suggestion',
                          action: 'skipped',
                        })
                      }}
                    />
                  )}
                  {item.__typename === 'FeedItemVendorSuggestion' && (
                    <VendorSuggestion
                      id={
                        (item as FeedItemVendorSuggestion).vendorSuggestion
                          .vendorId
                      }
                      skip={() => {
                        scrollToNextItem(idx + 1)
                        analytics?.feedItemAction({
                          itemId: item.id,
                          durationMs: 1,
                          type: 'vendor-suggestion',
                          action: 'skipped',
                        })
                      }}
                    />
                  )}
                  {item.__typename === 'FeedItemNotification' && (
                    <NotificationFeedItem
                      id={(item as FeedItemNotification).id}
                      scrollToNextItem={() => scrollToNextItem(idx + 1)}
                      feedItem={item as FeedItemNotification}
                      containerRef={containerRef}
                    />
                  )}
                  {item.__typename === 'FeedItemUnclaimedVendorSuggestion' && (
                    <UnclaimedSuggestion
                      ids={
                        (item as FeedItemUnclaimedVendorSuggestion)
                          .unclaimedVendorSuggestion.vendorsIds
                      }
                      skip={() => {
                        scrollToNextItem(idx + 1)
                        analytics?.feedItemAction({
                          itemId: item.id,
                          durationMs: 1,
                          type: 'unclaimed-suggestion',
                          action: 'skipped',
                        })
                      }}
                    />
                  )}
                </FeedCard>
                <Box
                  style={{
                    alignSelf: 'flex-end',
                  }}
                  visibleFrom="md"
                >
                  <Stack>
                    <ActionIcon
                      variant="filled"
                      size="lg"
                      radius="xl"
                      aria-label="Scroll Up"
                      c="white"
                      onClick={() =>
                        items[idx - 1]
                          ? handleScrollUp(`${items[idx - 1].id}-${idx - 1}`)
                          : null
                      }
                    >
                      <FontAwesomeIcon icon={faUp} />
                    </ActionIcon>
                    <ActionIcon
                      variant="filled"
                      size="lg"
                      radius="xl"
                      aria-label="Scroll Down"
                      c="white"
                      onClick={() =>
                        items[idx + 1]
                          ? handleScrollDown(`${items[idx + 1].id}-${idx + 1}`)
                          : null
                      }
                    >
                      <FontAwesomeIcon icon={faDown} />
                    </ActionIcon>
                  </Stack>
                </Box>
              </Flex>
              {idx === items.length - 1 && <Box h="5%" />}
            </React.Fragment>
          )
        })}
      </Box>
    </AppShell.Main>
  )
}
