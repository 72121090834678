import {
  faCircleCheck,
  faCircleExclamation,
  faClock,
  faEye,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InteractionStatus, Money } from '@graphql'
import { SwayCashIcon } from '@icons/SwayCash'
import { Box, Button, Text } from '@mantine/core'
import { SEMI_BOLD, timeDifference } from '@util/utils'
import { Direction } from '../message.types'
import { InteractionTimeRemaining } from './InteractionTimeRemaining'

type ClickInteractionProps = {
  direction: Direction
  earningPotential?: Money
  expiresAt?: string
  interactionId: string
  receiver: {
    firstName: string
    lastName: string
    id: string
  }
  status: InteractionStatus
  onClickInteraction: (interactionId: string) => void
}

export const ClickInteraction = ({
  direction,
  earningPotential,
  expiresAt,
  interactionId,
  receiver,
  status,
  onClickInteraction,
}: ClickInteractionProps) => {
  return (
    <>
      {direction === 'incoming' && status === InteractionStatus.Pending && (
        <PendingClickInteraction
          earningPotential={earningPotential}
          expiresAt={expiresAt}
          interactionId={interactionId}
          onClickInteraction={onClickInteraction}
        />
      )}
      {direction === 'incoming' && status === InteractionStatus.Complete && (
        <CompleteClickInteraction earningPotential={earningPotential} />
      )}
      {direction === 'incoming' && status === InteractionStatus.Expired && (
        <ExpiredClickInteraction expiresAt={expiresAt} />
      )}
      {direction === 'outgoing' && status === InteractionStatus.Pending && (
        <OutgoingPendingClickInteraction
          earningPotential={earningPotential}
          expiresAt={expiresAt}
          receiver={receiver}
        />
      )}
      {direction === 'outgoing' && status === InteractionStatus.Complete && (
        <OutgoingCompleteClickInteraction
          earningPotential={earningPotential}
          receiver={receiver}
        />
      )}
      {direction === 'outgoing' && status === InteractionStatus.Expired && (
        <OutgoingExpiredClickInteraction />
      )}
    </>
  )
}

function PendingClickInteraction({
  earningPotential,
  expiresAt,
  interactionId,
  onClickInteraction,
}: {
  earningPotential?: Money
  expiresAt?: string
  interactionId: string
  onClickInteraction: (interactionId: string) => void
}) {
  if (
    earningPotential?.amount === undefined ||
    earningPotential?.amount === null
  )
    return null

  const amount = earningPotential?.amount

  return (
    <Box p={8}>
      <InteractionTimeRemaining
        earningPotential={earningPotential}
        expiresAt={expiresAt}
      />
      <Button
        onClick={() => onClickInteraction(interactionId)}
        leftSection={
          <FontAwesomeIcon className="inline-block h-5 w-5" icon={faEye} />
        }
        fullWidth
      >
        <Text c="white">
          {amount > 0 && 'Click to earn'}
          {amount < 1 && 'Click to continue'}
        </Text>
      </Button>
    </Box>
  )
}

function CompleteClickInteraction({
  earningPotential,
}: {
  earningPotential?: Money
}) {
  if (!earningPotential?.amount) return null

  return (
    <Text size="sm" className="my-2 flex flex-wrap items-center p-2">
      <Text span inline c="green.8">
        <FontAwesomeIcon icon={faEye} className="mr-1 inline-block h-5 w-5" />
      </Text>
      Earned
      <SwayCashIcon className="mx-1 inline-block h-5 w-5" />
      <Text span fw={SEMI_BOLD}>
        {(earningPotential?.amount / 100).toFixed(2)} SC
      </Text>
    </Text>
  )
}

function ExpiredClickInteraction({ expiresAt }: { expiresAt?: string }) {
  return (
    <Text size="sm" c="red" className="my-2 flex flex-wrap items-center p-2">
      <FontAwesomeIcon
        icon={faCircleExclamation}
        className="mr-1 inline-block h-5 w-5"
      />
      Expired
      {expiresAt && (
        <>
          {' '}
          at{' '}
          {new Date(expiresAt).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          })}
        </>
      )}
    </Text>
  )
}

function OutgoingPendingClickInteraction({
  earningPotential,
  expiresAt,
  receiver,
}: {
  earningPotential?: Money
  expiresAt?: string
  receiver: {
    firstName: string
    lastName: string
    id: string
  }
}) {
  if (
    earningPotential?.amount === undefined ||
    earningPotential?.amount === null
  )
    return null
  const expiresIn = expiresAt ? timeDifference(new Date(expiresAt)) : null

  if (!expiresIn)
    return (
      <Text size="sm" className="my-2 flex flex-wrap items-center p-2">
        <FontAwesomeIcon icon={faClock} className="mr-1 inline-block h-5 w-5" />
        {receiver.firstName} can still earn by viewing your message
      </Text>
    )

  return (
    <Text size="sm" className="my-2 flex flex-wrap items-center p-2">
      <FontAwesomeIcon icon={faClock} className="mr-1 inline-block h-5 w-5" />
      {receiver.firstName} has
      <Text span fw={SEMI_BOLD} mx={4}>
        {`${expiresIn.hours} hours ${expiresIn.minutes} minutes `}
      </Text>
      remaining to view your message.
    </Text>
  )
}

function OutgoingCompleteClickInteraction({
  earningPotential,
  receiver,
}: {
  earningPotential?: Money
  receiver: {
    firstName: string
    lastName: string
    id: string
  }
}) {
  if (
    earningPotential?.amount === undefined ||
    earningPotential?.amount === null
  )
    return null

  return (
    <>
      <Text size="sm" className="my-2 flex flex-wrap items-center p-2">
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="mr-1 inline-block h-5 w-5"
        />
        {receiver.firstName} viewed your message and you paid
        {earningPotential?.amount && (
          <>
            <SwayCashIcon
              color="white"
              className="mx-1 inline-block h-5 w-5 text-white"
            />
            <Text span fw={SEMI_BOLD}>
              {(earningPotential?.amount / 100).toFixed(2)}
            </Text>
          </>
        )}
      </Text>
    </>
  )
}

function OutgoingExpiredClickInteraction() {
  return (
    <Text size="sm" className="my-2 flex flex-wrap items-center p-2">
      <FontAwesomeIcon
        icon={faCircleExclamation}
        className="inline-block h-5 w-5"
      />
      <Text span fw={SEMI_BOLD} mx={4}>
        Message Expired
      </Text>
      and you were not charged
    </Text>
  )
}
