import {
  InteractionClick,
  InteractionStatus,
  useInteractionClickMutation,
} from '@graphql'
import { SwayCashIcon } from '@icons/SwayCash'
import { Box, Button, Center, Modal, Stack, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@util/notifications/notifications'
import { BOLD } from '@util/utils'
import { useCallback, useEffect, useState } from 'react'
import Confetti from 'react-confetti'
import { BaseFeedInteraction } from './Interactions.types'

export function ClickInteraction({
  interaction,
  completedInteraction,
}: BaseFeedInteraction & { interaction: InteractionClick }) {
  const [completed, setCompleted] = useState(false)
  const [interactionClick, { loading }] = useInteractionClickMutation()
  const [
    celebrationModalOpened,
    { open: openCelebrationModal, close: closeCelebrationModal },
  ] = useDisclosure(false)

  const handleOnClickInteraction = useCallback(
    (interactionId: string) => {
      if (loading) return

      interactionClick({
        variables: {
          interactionId,
        },
        onCompleted: (response) => {
          if (response.interactionClick.successful) {
            setCompleted(true)
            openCelebrationModal()

            setTimeout(() => {
              // Move on to the next feed item
              completedInteraction(
                response.interactionClick.result?.followUpInteraction?.id ??
                  undefined
              )
              closeCelebrationModal()
            }, 2000)
          } else {
            notifications.show({
              color: 'red',
              title: 'Error submitting click',
              message: 'Please try again later',
            })
          }
        },
        onError: (error) => {
          notifications.show({
            color: 'red',
            title: 'Error submitted click',
            message: `${error.message}`,
          })
        },
      })
    },
    [
      closeCelebrationModal,
      completedInteraction,
      interactionClick,
      loading,
      openCelebrationModal,
    ]
  )
  const earnedAmount = interaction.earningPotential?.amount
    ? `$${(interaction.earningPotential?.amount / 100).toFixed(2)} SwayCash`
    : 'SwayCash'

  const earnedButtonOption = {
    c: 'green.8',
  }

  useEffect(() => {
    if (interaction.status === InteractionStatus.Complete) {
      setCompleted(true)
    }
  }, [interaction])

  return (
    <>
      <Stack pb={8} className="flex-shrink-0" mt="auto">
        <Button
          fullWidth
          onClick={() => handleOnClickInteraction(interaction.id)}
          disabled={completed}
          {...(completed && earnedButtonOption)}
        >
          {completed ? (
            `Earned ${earnedAmount}`
          ) : (
            <Text>
              {`Click to Earn`}
              {interaction.earningPotential?.amount &&
                ` ${(interaction.earningPotential?.amount / 100).toFixed(2)} SC`}
            </Text>
          )}
        </Button>
      </Stack>

      <Modal
        opened={celebrationModalOpened}
        onClose={closeCelebrationModal}
        radius={0}
        centered
        transitionProps={{ transition: 'fade', duration: 200 }}
        withCloseButton={false}
        styles={{
          header: {
            background: 'green',
          },
          content: {
            background: 'green',
          },
        }}
      >
        <Stack justify="center">
          <Center>
            <Box style={{ borderRadius: 5000 }} bg="white" p={4}>
              <SwayCashIcon className="h=10 w-10" />
            </Box>
          </Center>

          <Text size="xl" fw={BOLD} c="white" ta="center">
            You{' '}
            <Text px={2} span fw={BOLD}>
              earned{' '}
              {interaction?.earningPotential?.amount
                ? `${(interaction.earningPotential?.amount / 100).toFixed(2)} SwayCash`
                : 'SwayCash'}
              !
            </Text>
          </Text>
        </Stack>
        {celebrationModalOpened && <Confetti initialVelocityY={40} />}
      </Modal>
    </>
  )
}
