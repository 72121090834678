import { GlobalLimitBadge } from '@components/limitBadges/GlobalLimitBadge'
import { IntervalLimitBadge } from '@components/limitBadges/IntervalLimitBadge'
import { MemberLimitBadge } from '@components/limitBadges/MemberLimitBadge'
import { ValidEndLimitBadge } from '@components/limitBadges/ValidEndLimitBadge'
import {
  RedemptionLimit,
  RedemptionLimitRate,
  RedemptionLimitScope,
  RedemptionLimitType,
} from '@graphql'
import { Stack } from '@mantine/core'
import { findLimit } from '@util/redemptionsUtils'

type FeedRedemptionLimitsProps = {
  limits: RedemptionLimit[] | null | undefined
  validEnd: string | null
}

export const FeedRedemptionLimits = ({
  limits,
  validEnd,
}: FeedRedemptionLimitsProps) => {
  const globalLimit = findLimit(
    limits || [],
    RedemptionLimitType.Count,
    RedemptionLimitScope.Global
  ) as RedemptionLimit
  const memberLimit = findLimit(
    limits || [],
    RedemptionLimitType.Count,
    RedemptionLimitScope.User
  ) as RedemptionLimit
  const intervalLimit = findLimit(
    limits || [],
    RedemptionLimitType.RateLimit,
    RedemptionLimitScope.User
  ) as RedemptionLimit

  return (
    <Stack pos="absolute" left={8} top={60}>
      {validEnd && <ValidEndLimitBadge redemptionValidEnd={validEnd} />}
      {globalLimit && <GlobalLimitBadge {...globalLimit} />}
      {memberLimit && <MemberLimitBadge {...memberLimit} />}
      {intervalLimit && (
        <IntervalLimitBadge {...(intervalLimit as RedemptionLimitRate)} />
      )}
    </Stack>
  )
}
