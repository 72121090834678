import { ApolloClient, ApolloError } from '@apollo/client'
import {
  BlockPartsFragment,
  BlockSubscriptionPartsFragment,
  ConversationDocument,
  ConversationPartsFragment,
  ConversationQuery,
  Message,
  MessagePartsFragment,
  TypingSubscriptionPartsFragment,
  useBlockProfileMutation,
  useConversationEventSubscription,
  useConversationQuery,
  useMarkViewedMutation,
} from '@graphql'
import { noop } from 'lodash-es'
import React, { createContext, useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

export interface DialogueContextType {
  blockProfileLoading: boolean
  conversation: ConversationPartsFragment | undefined
  conversationError: ApolloError | undefined
  conversationLoading: boolean
  interactionModalMessage: Message | null
  isReplying: boolean
  isReporting: boolean
  partnerIsTyping: boolean
  reportMessage: MessagePartsFragment | null
  setInteractionModalMessageId: (
    interactionModalMessageId: string | null
  ) => void
  setReportMessage: (message: MessagePartsFragment | null) => void
  blockUser: (conversationId: string, profileId: string) => void
  markViewed: () => void
}

export const DialogueContext = createContext<DialogueContextType>({
  blockProfileLoading: false,
  conversation: {} as ConversationPartsFragment,
  conversationError: undefined,
  conversationLoading: false,
  interactionModalMessage: null,
  isReplying: false,
  isReporting: false,
  partnerIsTyping: false,
  reportMessage: null,
  setInteractionModalMessageId: () => noop(),
  setReportMessage: () => noop(),
  blockUser: () => noop(),
  markViewed: () => noop(),
})

export function DialogueContextProvider({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const [interactionModalMessageId, setInteractionModalMessageId] = useState<
    string | null
  >(null)
  const [reportMessage, setReportMessage] =
    useState<MessagePartsFragment | null>(null)
  const [partnerIsTyping, setPartnerIsTyping] = useState(false)

  const isTypingTimeoutId = React.useRef<NodeJS.Timeout | null>(null)

  const params = useParams()

  const {
    data: conversationData,
    loading,
    error,
  } = useConversationQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      id: params?.conversationId || '',
      messagesQuantity: 25,
    },
  })

  useConversationEventSubscription({
    variables: {
      conversationId: params?.conversationId || '',
    },
    onData: (data) => {
      const conversationEvent = data.data?.data?.conversationEvent
      const client = data.client as unknown as ApolloClient<any>

      if (!conversationEvent) return

      const { __typename } = conversationEvent

      switch (__typename) {
        case 'MessageEvent':
          handleSubscriptionMessageEvent(
            client,
            conversationEvent as unknown as MessageEvent
          )
          break
        case 'BlockEvent':
          handleBlockEvent(client, conversationEvent)
          break
        case 'TypingEvent':
          handleTypingEvent(conversationEvent)
          break
      }
    },
  })

  const [blockProfileMutation, { loading: blockProfileLoading }] =
    useBlockProfileMutation()

  const [markViewedMutation] = useMarkViewedMutation()

  const handleTypingEvent = useCallback(
    (event: TypingSubscriptionPartsFragment) => {
      if (isTypingTimeoutId.current) {
        clearTimeout(isTypingTimeoutId.current)
      }

      if (
        event.profile.id === conversationData?.conversation?.partnerProfile.id
      ) {
        setPartnerIsTyping(true)
        isTypingTimeoutId.current = setTimeout(
          () => setPartnerIsTyping(() => false),
          3000
        )
      }
    },
    [conversationData?.conversation?.partnerProfile.id]
  )

  const blockUser = useCallback(
    (conversationId: string, profileId: string) => {
      blockProfileMutation({
        variables: {
          conversationId,
          profileId,
        },
        optimisticResponse: {
          blockProfile: {
            id: new Date().toUTCString(), // These are required by typescript but are not used
            blockingProfile: {
              id: '', // These are required by typescript but are not used
            },
            blockedProfile: {
              id: profileId,
            },
            insertedAt: new Date().toUTCString(),
          } as BlockPartsFragment,
        },
      })
    },
    [blockProfileMutation]
  )

  function handleSubscriptionMessageEvent(
    client: ApolloClient<any>,
    conversationEvent: MessageEvent
  ): void {
    if (!conversationEvent) return
    if (!('message' in conversationEvent)) return

    const conversation = client.readQuery<ConversationQuery>({
      query: ConversationDocument,
      variables: {
        id: params?.conversationId || '',
        messagesQuantity: 25,
      },
    })

    if (!conversation) return

    const message = conversationEvent.message as MessagePartsFragment

    const cachedConversationMessages = conversation.conversation
      .messages as MessagePartsFragment[]

    const alreadyHasMessage = cachedConversationMessages.some(
      (m: MessagePartsFragment) => m?.id === message.id
    )

    let updatedMessages: Array<MessagePartsFragment | null> = []

    if (alreadyHasMessage) {
      updatedMessages = cachedConversationMessages.map(
        (m: MessagePartsFragment | null) => {
          if (m?.id === message.id) {
            return message
          }
          return m
        }
      )
    } else {
      updatedMessages = [...cachedConversationMessages, message]
    }

    client.writeQuery<ConversationQuery>({
      query: ConversationDocument,
      variables: {
        id: params?.conversationId || '',
        messagesQuantity: 25,
      },
      data: {
        conversation: {
          ...conversation.conversation,
          messages: updatedMessages,
        },
      },
    })

    setInteractionModalMessageId(null)
  }

  function handleBlockEvent(
    client: ApolloClient<any>,
    event: BlockSubscriptionPartsFragment
  ) {
    if (!event) return

    const conversation = client.readQuery<ConversationQuery>({
      query: ConversationDocument,
      variables: {
        id: params?.conversationId || '',
        messagesQuantity: 25,
      },
    })

    if (!conversation) return

    if (event.blockType === 'BLOCK_ADDED') {
      const block = event.block as BlockPartsFragment

      client.writeQuery<ConversationQuery>({
        query: ConversationDocument,
        variables: {
          id: params?.conversationId || '',
          messagesQuantity: 25,
        },
        data: {
          conversation: {
            ...conversation.conversation,
            blocks: [...conversation.conversation.blocks, block],
          },
        },
      })
    } else if (event.blockType === 'BLOCK_REMOVED') {
      const block = event.block as BlockPartsFragment

      client.writeQuery<ConversationQuery>({
        query: ConversationDocument,
        variables: {
          id: params?.conversationId || '',
          messagesQuantity: 25,
        },
        data: {
          conversation: {
            ...conversation.conversation,
            blocks: conversation.conversation.blocks.filter(
              (b) => b.id !== block.id
            ),
          },
        },
      })
    }
  }

  const markViewed = useCallback(
    () =>
      markViewedMutation({
        variables: {
          conversationId: params?.conversationId || '',
        },
      }),
    [markViewedMutation, params?.conversationId]
  )

  const memoedValue = useMemo(() => {
    const interactionModalMessage =
      (conversationData?.conversation?.messages?.find(
        (message) => message?.id === interactionModalMessageId
      ) as Message) || null

    return {
      conversation: conversationData?.conversation,
      conversationLoading: loading,
      conversationError: error,
      interactionModalMessage,
      isReplying: !!interactionModalMessage,
      partnerIsTyping,
      reportMessage,
      isReporting: !!reportMessage,
      blockProfileLoading,
      setInteractionModalMessageId,
      setReportMessage,
      blockUser,
      markViewed,
    }
  }, [
    conversationData?.conversation,
    loading,
    error,
    interactionModalMessageId,
    partnerIsTyping,
    reportMessage,
    blockProfileLoading,
    blockUser,
    markViewed,
  ])

  return (
    <DialogueContext.Provider value={memoedValue}>
      {children}
    </DialogueContext.Provider>
  )
}
