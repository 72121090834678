import {
  InteractionClick,
  InteractionPoll,
  InteractionReply,
  InteractionType,
  useFeedGetSingleInteractionQuery,
} from '@graphql'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { Drawer, Stack } from '@mantine/core'
import { generateImgixOptions } from '@util/imgixUtils'
import { ClickInteraction } from './Click.interaction'
import { FeedCardTextContent } from './FeedCardTextContent'
import { PollInteraction } from './Poll.interaction'
import { ReplyInteraction } from './Reply.interaction'

export function FollowUpInteraction({
  followUpOpened,
  containerUUID,
  followUpInteraction,
  closeFollowUp,
  handleCompletedInteraction,
}: {
  followUpOpened: boolean
  containerUUID: string
  followUpInteraction:
    | InteractionPoll
    | InteractionReply
    | InteractionClick
    | undefined
  closeFollowUp: () => void
  handleCompletedInteraction: (followUpId: string | undefined) => void
}) {
  const { data, loading } = useFeedGetSingleInteractionQuery({
    variables: {
      id: followUpInteraction?.id || '',
      imgixOpts: generateImgixOptions({
        ar: '5:3',
        w: 500,
        h: 300,
        auto: 'compress',
      }),
    },
    skip: !followUpInteraction?.id,
    fetchPolicy: 'network-only',
  })

  const interactionToUse = data?.oneInteraction

  const isMobile = useIsMobileViewport()

  const message =
    (interactionToUse as any)?.message ||
    (interactionToUse as any)?.replyMessage ||
    (interactionToUse as any)?.pollMessage ||
    (interactionToUse as any)?.clickMessage ||
    (interactionToUse as any)?.redemptionMessage ||
    (interactionToUse as any)?.redemptionOfferMessage

  const messageBody = message?.body

  return (
    <Drawer.Root
      offset={8}
      radius="md"
      opened={followUpOpened}
      onClose={closeFollowUp}
      position="bottom"
      portalProps={{ target: `#${containerUUID}` }}
      c="black"
    >
      <Drawer.Overlay
        backgroundOpacity={0.5}
        blur={4}
        pos="absolute"
        radius={isMobile ? undefined : 'lg'}
      />
      <Drawer.Content
        style={{ width: '98%', position: 'absolute', height: 'auto' }}
      >
        <Drawer.Header>
          <Drawer.Title>Follow Up Message</Drawer.Title>
          <Drawer.CloseButton />
        </Drawer.Header>
        <Drawer.Body>
          {loading || !interactionToUse ? (
            <p>Loading...</p>
          ) : (
            <>
              {messageBody && <FeedCardTextContent body={messageBody} />}
              {interactionToUse?.interactionType === InteractionType.Poll &&
                interactionToUse.id && (
                  <Stack>
                    <PollInteraction
                      id={interactionToUse.id}
                      poll={interactionToUse as InteractionPoll}
                      completedInteraction={(followUpId: string | undefined) =>
                        handleCompletedInteraction(followUpId)
                      }
                    />
                  </Stack>
                )}
              {interactionToUse?.interactionType === InteractionType.Reply &&
                messageBody && (
                  <ReplyInteraction
                    id={interactionToUse.id}
                    interaction={interactionToUse as InteractionReply}
                    completedInteraction={(followUpId: string | undefined) =>
                      handleCompletedInteraction(followUpId)
                    }
                  />
                )}
              {interactionToUse?.interactionType === InteractionType.Click && (
                <ClickInteraction
                  id={interactionToUse.id}
                  interaction={interactionToUse as InteractionClick}
                  completedInteraction={(followUpId: string | undefined) =>
                    handleCompletedInteraction(followUpId)
                  }
                />
              )}
            </>
          )}
        </Drawer.Body>
      </Drawer.Content>
    </Drawer.Root>
  )
}
