import {
  CommunityDiscoveryQueryAttributes,
  PageFilterOp,
  useFeedGetVendorsQuery,
  useFollowCommunityMutation,
} from '@graphql'
import { useBreakpointHeight } from '@hooks/useBreakpoint'
import { SwayIcon } from '@icons/Sway'
import {
  Button,
  Card,
  Flex,
  Group,
  Loader,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { notifications } from '@util/notifications/notifications'
import { BOLD } from '@util/utils'
import { useState } from 'react'

export function UnclaimedSuggestion({
  ids,
  skip,
}: {
  ids: string[]
  skip: () => void
}) {
  const [followCommunity] = useFollowCommunityMutation()
  const { data, loading } = useFeedGetVendorsQuery({
    variables: {
      query: {
        filters: [
          {
            attribute: CommunityDiscoveryQueryAttributes.Id,
            op: PageFilterOp.In,
            value: {
              strings: ids,
            },
          },
        ],
      },
    },
  })

  const isSmallScreen = useBreakpointHeight('max', 'sm')

  const [communitiesFollowed, setCommunitiesFollowed] = useState<
    Map<string, boolean>
  >(new Map())

  const communities =
    data?.discoverCommunities?.data.map((entry) => entry.community) || []

  const handleFollow = (communityId: string) => {
    followCommunity({
      variables: {
        communityId,
      },
    })
      .then(() => {
        notifications.show({
          title: 'Community followed',
          message: 'You are now following this community',
        })

        const newCommunitiesFollowed = new Map(
          communitiesFollowed.set(communityId, true)
        )

        if (newCommunitiesFollowed.size === communities.length) {
          // All suggested communities followed
          skip()
        }

        setCommunitiesFollowed(newCommunitiesFollowed)
      })
      .catch((error) => {
        notifications.show({
          title: 'Failed to follow community',
          message: error.message,
          color: 'red',
        })
      })
  }

  return (
    <>
      {loading ? (
        <Loader color="swurple" size="xl" type="dots" />
      ) : (
        <Stack
          pos="relative"
          h="100%"
          w="100%"
          bg={'#46b5bc'}
          justify="center"
          p={24}
          gap={18}
        >
          <Stack gap={4}>
            <Title
              c="white"
              fw={BOLD}
              ta="center"
              order={isSmallScreen ? 3 : 2}
            >
              Want to see your favorite local business on SwayDM?
            </Title>
            <Text c="white" ta="center">
              Follow their profiles to show your interest!
            </Text>
          </Stack>

          {communities.map((community) => (
            <Card
              key={community.id}
              shadow="xs"
              padding="md"
              radius="md"
              bg="white"
              className="z-10"
            >
              <Stack w={'100%'} align="center" gap={8}>
                <Text>{community.communityName}</Text>
                {/* <Group> TODO: Add tags badges </Group> */}
                <Group w="100%" wrap="nowrap" gap={8}>
                  <Button
                    size={isSmallScreen ? 'compact-sm' : 'md'}
                    fullWidth
                    disabled={communitiesFollowed.get(community.id)}
                    onClick={() => handleFollow(community.id)}
                  >
                    {communitiesFollowed.get(community.id)
                      ? 'Following'
                      : 'Follow'}
                  </Button>
                  {/*
                    TODO:
                    Thumbs down button, not applicable yet.
                    <ActionIcon size="lg" color={'deep-blue'} variant="outline">
                      <FontAwesomeIcon icon={faThumbsDown} />
                    </ActionIcon>
                  */}
                </Group>
              </Stack>
            </Card>
          ))}

          {/* Background Icon */}
          <Flex
            className="absolute inset-0 m-auto h-lvh w-lvw scale-200 opacity-10"
            justify={'center'}
          >
            <SwayIcon color="white" className="h-auto w-auto -rotate-12" />
          </Flex>
        </Stack>
      )}
    </>
  )
}
