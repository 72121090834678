import { print } from '@apollo/client/utilities'
import useAppContext from '@context/appContext/useAppContext'
import { LogoutDocument } from '@graphql'
import { useNotifications } from '@mantine/notifications'
import useAuthStore, { UserState } from '@stores/useAuthStore'
import {
  notificationAlreadyAdded,
  notifications,
} from '@util/notifications/notifications'
import { useCallback } from 'react'
import useAnalytics from './useAnalytics'

const ROOT_API_SERVER_URL = import.meta.env.VITE_ROOT_API_SERVER_URL

export const useAuth = () => {
  const notificationsStore = useNotifications()
  const analytics = useAnalytics()
  const { deviceId } = useAppContext()
  const { currentUser, allUsers, onLogin, onLogout, switchUser } = useAuthStore(
    (state) => ({
      currentUser: state.currentUser,
      allUsers: state.allUsers,
      onLogin: state.onLogin,
      onLogout: state.onLogout,
      switchUser: state.switchUser,
    })
  )

  const logoutMutation = print(LogoutDocument)

  const handleLogin = useCallback(
    ({ newUser }: { newUser: UserState }) => {
      onLogin(newUser)
      notifications.show({
        message: `Signed in as ${newUser.displayName}`,
        autoClose: 2000,
      })
      analytics.userLogin({ userId: newUser.id })
    },
    [onLogin, analytics]
  )

  const handleLogout = useCallback(
    (
      userId: string | undefined | null,
      options?: { isExpiredSession?: boolean }
    ) => {
      const userIsInAllUsers = allUsers.find((user) => user.id === userId)

      if (!userId || currentUser?.id !== userId || !userIsInAllUsers) {
        return
      }

      onLogout(userId)
      analytics.userLogout({ userId })

      fetch(`${ROOT_API_SERVER_URL}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentUser.accessToken?.value}`,
        },
        credentials: 'include',
        body: JSON.stringify({
          query: logoutMutation,
          variables: { deviceId },
        }),
      }).catch((error) =>
        console.error(
          `Error with logout mutation for user ${currentUser.id}:`,
          error
        )
      )

      const isExpiredSession = options?.isExpiredSession || false
      if (isExpiredSession) {
        const message = 'Your session has expired, please log in again'

        !notificationAlreadyAdded(notificationsStore, message) &&
          notifications.show({
            message,
            autoClose: 2000,
            onClose: () => {
              notifications.cleanQueue()
            },
          })
      } else {
        const message = `Signed out`

        !notificationAlreadyAdded(notificationsStore, message) &&
          notifications.show({
            message,
            autoClose: 2000,
          })
      }
    },
    [
      allUsers,
      analytics,
      currentUser?.accessToken?.value,
      currentUser?.id,
      deviceId,
      logoutMutation,
      notificationsStore,
      onLogout,
    ]
  )

  const handleSwitchUser = useCallback(
    (userId: string) => {
      switchUser(userId)
      const newUser = allUsers.find((user) => user.id === userId)
      analytics.userSwitch({
        fromUserId: currentUser?.id as string,
        toUserId: userId,
      })

      const isExpiredSession =
        new Date(newUser?.accessToken?.validUntil || '').getTime() < Date.now()

      if (!isExpiredSession) {
        notifications.show({
          message: `Switched to ${newUser?.displayName}`,
          autoClose: 2000,
        })
      }
    },
    [allUsers, analytics, currentUser?.id, switchUser]
  )

  return {
    currentUser,
    allUsers,
    onLogin: handleLogin,
    onLogout: handleLogout,
    switchUser: handleSwitchUser,
  }
}
