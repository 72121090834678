import useAppContext from '@context/appContext/useAppContext'
import { useConversationsContext } from '@context/conversationsContext'
import { useFeatureFlags } from '@context/index'
import {
  faArrowRightFromBracket,
  faBullhorn,
  faCircleQuestion,
  faGear,
  faHome,
  faMobileScreen,
  faQrcode,
  faStore,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxDollar, faMessages } from '@fortawesome/sharp-solid-svg-icons'
import { ProfileType, useWalletBalanceQuery } from '@graphql'
import { useAuth } from '@hooks/useAuth'
import { SwayIcon } from '@icons/Sway'
import { SwayCashIcon } from '@icons/SwayCash'
import { AppShell, Indicator, NavLink, Paper, Stack, Text } from '@mantine/core'
import { SEMI_BOLD } from '@util/utils'
import { useCallback } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import useLayoutContext from './useLayoutContext'

type SwayNavbarLink = {
  to?: string
  label: string
  leftSection: React.ReactNode
  rightSection?: React.ReactNode
  indicator?: number | string
  onClick?: () => void
}

export function SwayNavbar({ closeSidebar }: { closeSidebar: () => void }) {
  const { boot, show } = useIntercom()
  const { unreadMessagesCount } = useConversationsContext()
  const { currentUser, onLogout } = useAuth()

  const profileType = currentUser?.profileType
  const communityName = currentUser?.communityName

  const { data: walletBalanceData } = useWalletBalanceQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const walletBalance = walletBalanceData?.wallet?.balance.amount

  const handleOnLogout = useCallback(() => {
    if (currentUser?.id) onLogout(currentUser?.id)
  }, [onLogout, currentUser?.id])

  const handleIntercomSupport = useCallback(() => {
    boot()
    show()
  }, [boot, show])

  return (
    <>
      {profileType === ProfileType.CommunityFollower && (
        <CommunityFollowerNavbar
          unreadMessagesCount={unreadMessagesCount}
          user={{
            displayName: currentUser?.displayName,
            profilePictureUrl: currentUser?.profilePictureUrl,
            subtitle: currentUser?.communityName,
          }}
          walletBalance={walletBalance}
          closeSidebar={closeSidebar}
          onLogout={handleOnLogout}
          onIntercomSupport={handleIntercomSupport}
        />
      )}
      {profileType === ProfileType.Community && (
        <VendorNavbar
          unreadMessagesCount={unreadMessagesCount}
          user={{
            displayName: communityName,
            profilePictureUrl: currentUser?.profilePictureUrl,
            subtitle: currentUser?.displayName,
          }}
          walletBalance={walletBalance}
          closeSidebar={closeSidebar}
          onLogout={handleOnLogout}
          onIntercomSupport={handleIntercomSupport}
        />
      )}
      {profileType !== ProfileType.CommunityFollower &&
        profileType !== ProfileType.Community && (
          <FallbackNavbar onLogout={handleOnLogout} />
        )}
    </>
  )
}

function FallbackNavbar({ onLogout }: { onLogout: () => void }) {
  return (
    <>
      <AppShell.Section>
        <Stack gap={0}>
          <SwayNavLink
            key="logout"
            label="logout"
            leftSection={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
            onClick={onLogout}
          />
        </Stack>
      </AppShell.Section>
    </>
  )
}

function CommunityFollowerNavbar({
  unreadMessagesCount,
  // user,
  walletBalance,
  closeSidebar,
  onLogout,
  onIntercomSupport,
}: {
  unreadMessagesCount: number
  user: {
    displayName: string | undefined | null
    profilePictureUrl: string | undefined | null
    subtitle: string | undefined | null
  }
  walletBalance: number | undefined | null
  closeSidebar: () => void
  onLogout: () => void
  onIntercomSupport: () => void
}) {
  const { deviceInfo } = useAppContext()
  const { openGetAppModal } = useLayoutContext()
  const { contentFeed } = useFeatureFlags()

  const primaryLinks: Array<SwayNavbarLink> = [
    {
      to: '/conversations',
      label: 'Messages',
      leftSection: <FontAwesomeIcon icon={faMessages} className="h-5 w-5" />,
      indicator: unreadMessagesCount,
    },
    {
      to: '/redemptions',
      label: 'Redemptions',
      leftSection: <FontAwesomeIcon icon={faStore} className="h-5 w-5" />,
    },
    {
      to: '/referrals',
      label: 'Referrals',
      leftSection: <FontAwesomeIcon icon={faBoxDollar} className="h-5 w-5" />,
    },
    {
      to: '/earn',
      label: 'SwayCash',
      leftSection: <SwayCashIcon className="h-5 w-5" />,
    },
  ]

  contentFeed
    ? primaryLinks.unshift({
        to: '/feed',
        label: 'Home',
        leftSection: <FontAwesomeIcon icon={faHome} className="h-5 w-5" />,
      })
    : null

  const secondaryLinks: Array<SwayNavbarLink> = [
    {
      to: '/settings',
      label: 'Account Settings',
      leftSection: <FontAwesomeIcon icon={faGear} className="h-5 w-5" />,
    },
    deviceInfo?.platform === 'web'
      ? {
          label: 'Get the app',
          leftSection: (
            <FontAwesomeIcon icon={faMobileScreen} className="h-5 w-5" />
          ),
          onClick: () => {
            openGetAppModal()
          },
        }
      : undefined,
    {
      label: 'Contact Support',
      leftSection: (
        <FontAwesomeIcon icon={faCircleQuestion} className="h-5 w-5" />
      ),
      onClick: onIntercomSupport,
    },
    {
      to: 'https://www.sway.dm/info',
      label: 'About SwayDM',
      leftSection: <SwayIcon className="h-5 w-5" />,
    },
    {
      label: 'Log out',
      leftSection: (
        <FontAwesomeIcon icon={faArrowRightFromBracket} className="h-5 w-5" />
      ),
      onClick: onLogout,
    },
  ].filter((link) => link !== undefined) as Array<SwayNavbarLink>

  return (
    <>
      <AppShell.Section>
        <Stack gap={0}>
          {primaryLinks.map((link, index) => (
            <SwayNavLink
              key={`${link.label}-${index}`}
              to={link.to}
              label={link.label}
              leftSection={link.leftSection}
              rightSection={link.rightSection}
              indicator={link.indicator}
              onClick={() => {
                closeSidebar()
                link.onClick && link.onClick()
              }}
            />
          ))}
        </Stack>
      </AppShell.Section>
      <AppShell.Section grow>
        {walletBalance !== undefined && walletBalance !== null && (
          <Paper radius="md" shadow="md" p="md" withBorder maw={300}>
            <Text size="lg" fw={SEMI_BOLD}>
              SwayCash Balance
            </Text>
            <Text size="md" fw={SEMI_BOLD}>
              <SwayCashIcon className="mr-2 inline-block h-4 w-4" />
              {walletBalance && (walletBalance / 100).toFixed(2)} SC
            </Text>
          </Paper>
        )}
      </AppShell.Section>
      <AppShell.Section>
        <Stack gap={0}>
          {secondaryLinks.map((link, index) => (
            <SwayNavLink
              key={`${link.label}-${index}`}
              to={link.to}
              label={link.label}
              leftSection={link.leftSection}
              indicator={link.indicator}
              onClick={() => {
                closeSidebar()
                link.onClick && link.onClick()
              }}
            />
          ))}
        </Stack>
      </AppShell.Section>
    </>
  )
}

function VendorNavbar({
  unreadMessagesCount,
  walletBalance,
  closeSidebar,
  onLogout,
  onIntercomSupport,
}: {
  unreadMessagesCount: number
  user: {
    displayName: string | undefined | null
    profilePictureUrl: string | undefined | null
    subtitle: string | undefined | null
  }
  walletBalance: number | undefined | null
  closeSidebar: () => void
  onLogout: () => void
  onIntercomSupport: () => void
}) {
  const { deviceInfo } = useAppContext()
  const { openGetAppModal } = useLayoutContext()

  const primaryLinks: Array<SwayNavbarLink> = [
    {
      to: '/conversations',
      label: 'Messages',
      leftSection: <FontAwesomeIcon icon={faMessages} className="h-5 w-5" />,
      indicator: unreadMessagesCount,
    },
    {
      to: '/vendor/redemptions',
      label: 'Redemptions',
      leftSection: <FontAwesomeIcon icon={faStore} className="h-5 w-5" />,
    },
    {
      to: '/vendor/broadcasts',
      label: 'Broadcasts',
      leftSection: <FontAwesomeIcon icon={faBullhorn} className="h-5 w-5" />,
    },
    {
      to: '/earn',
      label: 'SwayCash',
      leftSection: <SwayCashIcon className="h-5 w-5" />,
    },
    {
      to: '/vendor/qrcodes',
      label: 'QR Codes',
      leftSection: <FontAwesomeIcon icon={faQrcode} className="h-5 w-5" />,
    },
  ]

  const secondaryLinks: Array<SwayNavbarLink> = [
    {
      to: '/settings',
      label: 'Account Settings',
      leftSection: <FontAwesomeIcon icon={faGear} className="h-5 w-5" />,
    },
    deviceInfo?.platform === 'web'
      ? {
          label: 'Get the app',
          leftSection: (
            <FontAwesomeIcon icon={faMobileScreen} className="h-5 w-5" />
          ),
          onClick: openGetAppModal,
        }
      : undefined,
    {
      label: 'Contact Support',
      leftSection: (
        <FontAwesomeIcon icon={faCircleQuestion} className="h-5 w-5" />
      ),
      onClick: onIntercomSupport,
    },
    {
      to: 'https://www.sway.dm/info',
      label: 'About SwayDM',
      leftSection: <SwayIcon className="h-5 w-5" />,
    },
    {
      label: 'Log out',
      leftSection: (
        <FontAwesomeIcon icon={faArrowRightFromBracket} className="h-5 w-5" />
      ),
      onClick: onLogout,
    },
  ].filter((link) => link !== undefined) as Array<SwayNavbarLink>

  return (
    <>
      <AppShell.Section>
        <Stack gap={0}>
          {primaryLinks.map((link, index) => (
            <SwayNavLink
              key={`${link.label}-${index}`}
              to={link.to}
              label={link.label}
              leftSection={link.leftSection}
              rightSection={link.rightSection}
              indicator={link.indicator}
              onClick={() => {
                closeSidebar()
                link.onClick && link.onClick()
              }}
            />
          ))}
          {deviceInfo?.platform === 'web' && (
            <SwayNavLink
              label="Mobile App"
              leftSection={
                <FontAwesomeIcon icon={faMobileScreen} className="w-4" />
              }
              onClick={() => {
                closeSidebar()
                openGetAppModal()
              }}
            />
          )}
        </Stack>
      </AppShell.Section>
      <AppShell.Section grow>
        {walletBalance !== undefined && walletBalance !== null && (
          <Paper radius="md" shadow="md" p="md" withBorder>
            <Text size="lg" fw={SEMI_BOLD}>
              SwayCash Balance
            </Text>
            <Text size="md" fw={SEMI_BOLD}>
              <SwayCashIcon className="mr-2 inline-block h-5 w-5" />
              {walletBalance && (walletBalance / 100).toFixed(2)} SC
            </Text>
          </Paper>
        )}
      </AppShell.Section>
      <AppShell.Section>
        <Stack gap={0}>
          {secondaryLinks.map((link, index) => (
            <SwayNavLink
              key={`${link.label}-${index}`}
              to={link.to}
              label={link.label}
              leftSection={link.leftSection}
              indicator={link.indicator}
              onClick={() => {
                closeSidebar()
                link.onClick && link.onClick()
              }}
            />
          ))}
        </Stack>
      </AppShell.Section>
    </>
  )
}

function SwayNavLink({
  to,
  label,
  leftSection,
  rightSection,
  indicator,
  onClick,
}: SwayNavbarLink) {
  const leftSectionWrapped = indicator ? (
    <Indicator label={indicator} size={16}>
      {leftSection}
    </Indicator>
  ) : (
    leftSection
  )

  const NavLinkContent = to ? (
    <NavLink
      component={RouterLink}
      to={to}
      label={label}
      leftSection={leftSectionWrapped}
      rightSection={rightSection}
      onClick={() => onClick?.()}
    />
  ) : (
    <NavLink
      component="button"
      label={label}
      leftSection={leftSectionWrapped}
      rightSection={rightSection}
      onClick={() => onClick?.()}
    />
  )

  return NavLinkContent
}
