import { ScrollAreaAutosize, Spoiler } from '@mantine/core'

export function FeedCardShowMore({ children }: { children: React.ReactNode }) {
  return (
    <ScrollAreaAutosize mah={200}>
      <Spoiler
        maxHeight={40}
        showLabel="Show more"
        hideLabel="Hide"
        styles={{
          control: {
            color: 'white',
          },
        }}
      >
        {children}
      </Spoiler>
    </ScrollAreaAutosize>
  )
}
