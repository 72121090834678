import {
  FeedNotification,
  Redemption,
  useFeedGetSingleRedemptionQuery,
} from '@graphql'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { SwayCashIcon } from '@icons/SwayCash'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Loader,
  Stack,
  Text,
} from '@mantine/core'
import { useIntersection } from '@mantine/hooks'
import { RedemptionDonationSection } from '@pages/redemptions/components/RedemptionDonationSection'
import { RedemptionFeedImage } from '@pages/redemptions/components/RedemptionFeedImage'
import { BOLD, SEMI_BOLD, htmlToText } from '@util/utils'
import { useEffect, useRef, useState } from 'react'
import Confetti from 'react-confetti'
import { useNavigate } from 'react-router-dom'

type DonationGoalReachedProps = {
  id: string
  scrollToNextItem: () => void
  notification: FeedNotification
  containerRef: React.RefObject<HTMLDivElement>
}

// A feed notification item for when a charity donation goal is reached.
// It shows:
// - The completed charity redemption.
// - A celebration confetti effect.
// - A CTA redirecting the member to the redemption list.
export function DonationGoalReached({
  id,
  scrollToNextItem,
  notification,
  containerRef,
}: DonationGoalReachedProps) {
  const [showConfetti, setShowConfetti] = useState(false)
  const confettiRef = useRef<HTMLCanvasElement>(null)

  const { ref, entry } = useIntersection({
    root: containerRef.current,
  })

  const isIntersecting = entry?.isIntersecting ?? false

  const redemptionId = notification.redemptionId as string
  const { data, loading } = useFeedGetSingleRedemptionQuery({
    variables: { id: redemptionId, evaluate: true },
  })

  const isMobileViewport = useIsMobileViewport()
  const navigate = useNavigate()

  const redemption = data?.getRedemption

  const authorProfile = redemption?.vendor

  // The confetti effect is shown only if the feed item is in the viewport, and lasts 5 seconds.
  useEffect(() => {
    if (isIntersecting) {
      setShowConfetti(true)

      setTimeout(() => {
        setShowConfetti(false)
      }, 5000)
    }
  }, [isIntersecting])

  const handleSeeMoreCharityRedemptions = () => {
    navigate(`/redemptions/offers`)
  }

  return (
    <>
      {loading ? (
        <Loader color="swurple" size="xl" type="dots" />
      ) : (
        <Stack
          h="100%"
          w="100%"
          mah="100%"
          gap={0}
          justify="space-between"
          pos="relative"
          ref={ref}
        >
          <Stack gap={0}>
            <Confetti
              ref={confettiRef}
              numberOfPieces={!showConfetti ? 0 : 200}
            />
            <RedemptionFeedImage
              redemption={redemption as Redemption}
              isMobileViewport={isMobileViewport}
              showExpirationSection={false}
            />
            <Flex
              px={8}
              py={8}
              justify="space-between"
              align="center"
              className="flex-shrink-0"
            >
              <Flex align="center">
                <Avatar src={authorProfile?.photoUrl} size="md" mr={8} />
                <Stack h="100%" gap={0} ml={4}>
                  <Text size={isMobileViewport ? 'sm' : 'md'} fw={SEMI_BOLD}>
                    {authorProfile?.name}
                  </Text>
                </Stack>
              </Flex>
              <Flex>
                {redemption?.price.amount && (
                  <Text size={isMobileViewport ? 'lg' : 'xl'} fw={BOLD}>
                    <SwayCashIcon className="mb-1 mr-0.5 inline-block h-5 w-5" />
                    {(redemption.price.amount / 100).toFixed(2)} SC
                  </Text>
                )}
              </Flex>
            </Flex>
            <Stack px={8} py={8} gap={4}>
              <Text fw={SEMI_BOLD} size={isMobileViewport ? 'lg' : 'xl'}>
                Goal Met: {redemption?.name}
              </Text>
              <Divider />
              <RedemptionDonationSection
                donationProgress={redemption?.donationProgress}
                isForDetailsPage={isMobileViewport ? false : true}
              />
              <Divider />
              <Text size={isMobileViewport ? 'md' : 'lg'}>
                {htmlToText(notification.body)}
              </Text>
            </Stack>
          </Stack>

          <Box id={`redemptionFeedCard-${redemption?.id}-${id}`}>
            <Stack
              p={16}
              style={{
                boxShadow:
                  '0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06)',
              }}
            >
              <Group wrap="nowrap" gap={8}>
                <Button
                  onClick={handleSeeMoreCharityRedemptions}
                  fullWidth
                  size={isMobileViewport ? 'compact-md' : 'lg'}
                  radius="sm"
                >
                  See more redemptions
                </Button>
              </Group>
              <Button
                fullWidth
                size={isMobileViewport ? 'compact-md' : 'lg'}
                radius="sm"
                variant="outline"
                c="deep-blue"
                color="deep-blue"
                onClick={() => scrollToNextItem()}
              >
                Got it
              </Button>
            </Stack>
          </Box>
        </Stack>
      )}
    </>
  )
}
