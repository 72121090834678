import { Text } from '@mantine/core'
import { ProfileInMessagePreview } from '@pages/conversations/Dialogue/components/ProfileInMessagePreview'
import { extractProfileLink } from '@util/extractProfileLink'
import React from 'react'
import isUrl from 'validator/lib/isURL'

export function FeedCardTextContent({ body }: { body: string }) {
  const messageParts = extractProfileLink(body)

  const paragraphs = messageParts.text.split('\n').map((paragraph, index) => (
    <Text key={index} mb={2}>
      {formatText(paragraph)}
    </Text>
  ))

  return (
    <>
      {paragraphs}
      {messageParts.id && (
        <ProfileInMessagePreview
          profileId={messageParts.id}
          withBorder
          withChevron
        />
      )}
    </>
  )
}

const formatText = (text: string) => {
  // Split text into words and map each word to a JSX element
  return text.split(/\s+/).map((word, index, _words) => {
    // Insert a space before each word except the first one in a paragraph
    const space = index > 0 ? ' ' : ''
    return (
      <React.Fragment key={index}>
        {space}
        {addMarkup(word, index)}
      </React.Fragment>
    )
  })
}

const addMarkup = (word: string, index: number) => {
  if (isUrl(word)) {
    return (
      <a
        href={word}
        target="_blank"
        rel="noopener noreferrer"
        key={index}
        className="break-words underline"
        style={{ wordBreak: 'break-all' }}
      >
        {word}
      </a>
    )
  }
  return <>{word}</>
}
