import redemptionPlaceholder from '@assets/redemption-placeholder-square.png'
import { MediaPointer, Profile, SignedUrlKey } from '@graphql'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { Box, Image, Skeleton } from '@mantine/core'
import { cn } from '@util/utils'
import { useState } from 'react'

type FeedImageProps = {
  primaryMedia: MediaPointer | null | undefined
  mediaUrl?: string | null | undefined
  // These are unused currently, but may be needed in the future
  linkedProfile: Partial<Profile> | null | undefined
  vendorId: string | undefined
}

export const FeedImage = ({ primaryMedia, mediaUrl }: FeedImageProps) => {
  const isMobileViewport = useIsMobileViewport()

  const [imageHasLoaded, setImageHasLoaded] = useState(false)
  const [imageHasError, setImageHasError] = useState(false)

  const downloadUrls = primaryMedia?.downloadUrls
  const thumbnailUrl = (primaryMedia as any)?.thumbnail?.url
    ? (primaryMedia as any).thumbnail.url
    : mediaUrl
      ? mediaUrl
      : downloadUrls
        ? downloadUrls.find((url) => url.key === SignedUrlKey.Thumbnail)?.url
        : undefined

  return (
    <Box
      h="100%"
      w="100%"
      pos="absolute"
      display="block"
      bg="swurple"
      style={{ overflow: 'hidden' }}
      className={!isMobileViewport ? 'rounded-xl' : ''}
    >
      {thumbnailUrl && !imageHasError ? (
        <>
          {!imageHasLoaded && (
            <Skeleton h="35vh" w="100%" style={{ zIndex: 1 }} radius={0} />
          )}
          <Image
            src={thumbnailUrl}
            fit="cover"
            h="100%"
            onLoad={() => setImageHasLoaded(true)}
            onError={() => setImageHasError(true)}
            className={cn(!imageHasLoaded && 'opacity-0')}
          />
        </>
      ) : (
        <Image
          src={redemptionPlaceholder}
          fit="cover"
          w="100%"
          h="100%"
          onLoad={() => setImageHasLoaded(true)}
          className={cn(!imageHasLoaded && 'opacity-0')}
        />
      )}
    </Box>
  )
}
