import { LoginModal } from '@components/modals/loginModal/LoginModal'
import {
  faCircleUser,
  faPlus,
  faStore,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAreaChart } from '@fortawesome/sharp-solid-svg-icons'
import { ProfileType } from '@graphql'
import { useAuth } from '@hooks/useAuth'
import {
  Avatar,
  Box,
  Group,
  Indicator,
  Menu,
  Stack,
  Text,
  UnstyledButton,
  rem,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { cn } from '@util/utils'
import { NavLink, useNavigate } from 'react-router-dom'

type UserAccount = {
  id: string
  profileType: ProfileType
  displayName: string
  communityName: string
  userRealName: string
  email: string
  avatarUrl: string
  impersonator:
    | {
        id: string
        email: string
      }
    | null
    | undefined
}

const AccountMenu = () => {
  const { allUsers, currentUser, switchUser } = useAuth()
  const navigate = useNavigate()

  const [opened, { open, close }] = useDisclosure()

  const userAcccounts: Array<UserAccount> = allUsers.map((user) => ({
    id: user.id || '',
    profileType: user.profileType || ProfileType.Unknown,
    displayName: user.displayName || '',
    communityName: user.communityName || '',
    userRealName: `${user.firstName} ${user.lastName}`,
    email: user.email || '',
    avatarUrl: user.profilePictureUrl || '',
    impersonator: user.impersonator,
  }))

  const currentUserAccount: UserAccount = {
    id: currentUser?.id || '',
    profileType: currentUser?.profileType || ProfileType.Unknown,
    displayName: currentUser?.displayName || '',
    communityName: currentUser?.communityName || '',
    userRealName: `${currentUser?.firstName} ${currentUser?.lastName}`,
    email: currentUser?.email || '',
    avatarUrl: currentUser?.profilePictureUrl || '',
    impersonator: currentUser?.impersonator,
  }

  if (!currentUser) return null

  return (
    <>
      <Menu
        shadow="md"
        width={260}
        position="bottom-end"
        transitionProps={{ transition: 'pop-top-right' }}
      >
        <Menu.Target>
          <UnstyledButton>
            <Group>
              <Indicator
                label={
                  <FontAwesomeIcon icon={faStore} height={12} width={12} />
                }
                size={24}
                offset={10}
                position="bottom-end"
                withBorder
                disabled={
                  currentUserAccount.profileType !== ProfileType.Community
                }
                color="teal"
                inline
              >
                <Box
                  className={cn(
                    'inline-block rounded-full p-0.5',
                    currentUserAccount.impersonator &&
                      'border-4 border-destructive'
                  )}
                >
                  <Avatar
                    src={currentUserAccount.avatarUrl}
                    alt={currentUserAccount.displayName}
                  />
                </Box>
              </Indicator>
              <Stack visibleFrom="md" gap={0} maw={200}>
                <Text truncate="end" fw={500}>
                  {currentUserAccount.userRealName}
                </Text>
                <Text truncate="end" size="sm">
                  {currentUserAccount.communityName ||
                    currentUserAccount.displayName}
                </Text>
              </Stack>
            </Group>
          </UnstyledButton>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>Current User</Menu.Label>
          <Menu.Item
            component={NavLink}
            to="/settings"
            leftSection={
              <Indicator
                label={<FontAwesomeIcon icon={faStore} height={8} width={8} />}
                size={18}
                offset={8}
                position="bottom-end"
                withBorder
                disabled={
                  currentUserAccount.profileType !== ProfileType.Community
                }
                color="teal"
                inline
              >
                <Box
                  className={cn(
                    'inline-block rounded-full p-0.5',
                    currentUserAccount.impersonator &&
                      'border-2 border-destructive'
                  )}
                >
                  <Avatar
                    src={currentUserAccount.avatarUrl}
                    alt={currentUserAccount.displayName}
                    size={rem(24)}
                  />
                </Box>
              </Indicator>
            }
            className="overflow-hidden"
            classNames={{
              itemLabel: 'overflow-hidden',
            }}
          >
            <Stack gap={0} miw={0} className="overflow-hidden">
              <Text truncate="end">{currentUserAccount.displayName}</Text>
              {currentUserAccount.impersonator && (
                <Text size="xs" c="red">
                  Impersonating
                </Text>
              )}
            </Stack>
          </Menu.Item>
          {userAcccounts.length > 1 && <Menu.Label>Switch User</Menu.Label>}
          {userAcccounts.map((user) => {
            if (user.id === currentUserAccount.id) return null
            return (
              <Menu.Item
                leftSection={
                  <Indicator
                    label={
                      <FontAwesomeIcon icon={faStore} height={8} width={8} />
                    }
                    size={18}
                    offset={8}
                    position="bottom-end"
                    withBorder
                    disabled={user.profileType !== ProfileType.Community}
                    color="teal"
                    inline
                  >
                    <Box
                      className={cn(
                        'inline-block rounded-full p-0.5',
                        user.impersonator && 'border-2 border-destructive'
                      )}
                    >
                      <Avatar
                        src={user.avatarUrl}
                        alt={user.displayName}
                        size={rem(24)}
                      />
                    </Box>
                  </Indicator>
                }
                key={user.id}
                onClick={() => {
                  switchUser(user.id)
                  navigate('/')
                }}
                classNames={{
                  itemLabel: 'overflow-hidden',
                }}
              >
                <Stack gap={0} miw={0} className="overflow-hidden">
                  <Text truncate="end">{user.displayName}</Text>
                  {user.impersonator && (
                    <Text size="xs" c="red">
                      Impersonating
                    </Text>
                  )}
                </Stack>
              </Menu.Item>
            )
          })}
          <Menu.Divider />
          <Menu.Item
            leftSection={
              <FontAwesomeIcon
                icon={faCircleUser}
                size="xl"
                style={{ height: rem(24), width: rem(24) }}
              />
            }
            component={NavLink}
            to={`/profile/${currentUserAccount.displayName}`}
          >
            View Your Profile
          </Menu.Item>
          {currentUserAccount.profileType === ProfileType.Community && (
            <Menu.Item
              leftSection={
                <FontAwesomeIcon
                  icon={faAreaChart}
                  size="xl"
                  style={{ height: rem(24), width: rem(24) }}
                />
              }
              onClick={() => navigate('/vendor/analytics')}
            >
              Weekly Analytics
            </Menu.Item>
          )}
          {(currentUserAccount.profileType === ProfileType.Community ||
            allUsers.find(
              (user) => user.profileType === ProfileType.Community
            )) && (
            <Menu.Item
              leftSection={
                <FontAwesomeIcon
                  icon={faPlus}
                  size="xl"
                  style={{ height: rem(24), width: rem(24) }}
                />
              }
              onClick={() => open()}
            >
              Add Another Account
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
      <LoginModal opened={opened} onClose={() => close()} />
    </>
  )
}

AccountMenu.displayName = 'SwayAppAccountMenu'

export { AccountMenu }
