import {
  Broadcast,
  BroadcastInput,
  PollOption,
  PollOptionType,
  PollTextOption,
  Redemption,
  RedemptionInput,
} from '@graphql'

export function convertBroadcast2BroadcastInput(
  broadcast: Partial<Broadcast>
): BroadcastInput {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename, ...allTargets } =
    broadcast.broadcastTarget as unknown as Record<string, unknown>

  const followUpMessageTemplate = broadcast.followUpMessageTemplate
  const messageTemplate = broadcast.messageTemplate

  return {
    // ! Currently there are no photo crop attributes in the BroadcastResponse
    attachmentPhotoCrop: undefined,
    broadcastName: broadcast.broadcastName || '',
    broadcastStart: broadcast.broadcastStart
      ? new Date(broadcast.broadcastStart)
      : undefined,
    broadcastTarget:
      Object.keys(allTargets).length > 0 ? allTargets : { allFollowers: true },
    followUpMessageTemplate: {
      body: followUpMessageTemplate?.body || '',
      type: followUpMessageTemplate?.type || undefined,
      options: followUpMessageTemplate?.options
        ? onlyPollTextOptions(
            followUpMessageTemplate.options as Array<PollOption>
          ).map((option) => {
            const text = option.text
            return { text }
          })
        : [],
      price: followUpMessageTemplate?.price?.amount || 100,
      primaryAttachmentId:
        followUpMessageTemplate?.primaryAttachment?.id || undefined,
      redemptionOffers: followUpMessageTemplate?.redemptionOffers
        ? convertRedemptionOffers2RedemptionOffersInput(
            followUpMessageTemplate.redemptionOffers as Array<Redemption>
          )
        : { offers: [] },
    },
    messageTemplate: {
      body: messageTemplate?.body || '',
      type: messageTemplate?.type || undefined,
      options: messageTemplate?.options
        ? onlyPollTextOptions(messageTemplate.options as Array<PollOption>).map(
            (option) => {
              const text = option.text
              return { text }
            }
          )
        : [],
      price: messageTemplate?.price?.amount || 100,
      primaryAttachmentId: messageTemplate?.primaryAttachment?.id || undefined,
      redemptionOffers: messageTemplate?.redemptionOffers
        ? convertRedemptionOffers2RedemptionOffersInput(
            messageTemplate.redemptionOffers as Array<Redemption>
          )
        : { offers: [] },
    },
    isPersistent: broadcast.isPersistent || false,
  }
}

function convertRedemptionOffers2RedemptionOffersInput(
  redemptionOffers: Array<Redemption>
): { offers: Array<RedemptionInput> } {
  return {
    offers: redemptionOffers.map((redemption) => {
      return {
        redemptionId: redemption.id,
      }
    }),
  }
}

function onlyPollTextOptions(
  options: Array<PollOption>
): Array<PollTextOption> {
  return options.filter(
    (option) => option !== null && option.type === PollOptionType.PollText
  ) as Array<PollTextOption>
}
