import {
  InteractionClick,
  InteractionPoll,
  InteractionReply,
  Message,
  useFeedGetSingleInteractionQuery,
} from '@graphql'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import {
  Box,
  Card,
  Center,
  Divider,
  Group,
  Loader,
  Popover,
  Stack,
  Text,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IMGIX_FEED_CONSTANTS } from '@pages/feed/constants/imgixFeed.constants'
import { generateImgixOptions } from '@util/imgixUtils'
import { BOLD, SEMI_BOLD } from '@util/utils'
import { uniqueId } from 'lodash-es'
import { useCallback, useState } from 'react'
import { FeedCardFooter } from '../FeedCardFooter'
import { FeedCardShowMore } from '../FeedCardShowMore'
import { FeedImage } from '../FeedImage'
import { Overlay } from '../Overlay'
import { FollowUpInteraction } from './FollowUp.interaction'
import { PollInteraction } from './Poll.interaction'

export function PollInteractionContainer({
  id,
  scrollToNextItem,
}: {
  id: string
  scrollToNextItem: () => void
}) {
  const isMobileViewport = useIsMobileViewport()
  const [containerUUID] = useState<string>(uniqueId('interactionContainer-'))
  const [followUpOpened, { open: openFollowUp, close: closeFollowUp }] =
    useDisclosure(false)

  const { data, loading } = useFeedGetSingleInteractionQuery({
    variables: {
      id,
      imgixOpts: generateImgixOptions({
        auto: 'compress',
        ...IMGIX_FEED_CONSTANTS,
      }),
    },
    fetchPolicy: 'network-only',
  })

  const poll = data?.oneInteraction as InteractionPoll

  const message = (poll as any)?.pollMessage as Message

  const displayName = message?.author?.displayName
  const authorPhoto = message?.author?.profilePictureUrl || undefined
  const body = message?.body
  const mediaAttachment = message?.attachments?.[0]

  const handleCompletedInteraction = useCallback(
    (followUpId: string | undefined) => {
      if (followUpId) {
        // ! This is temporarily commented out until the BE is ready
        // refetch()
        openFollowUp()
      } else {
        closeFollowUp()
        scrollToNextItem()
      }
    },
    [closeFollowUp, openFollowUp, scrollToNextItem]
  )

  return (
    <>
      {loading ? (
        <Loader color="swurple" size="xl" type="dots" />
      ) : (
        <>
          <Box
            h="100%"
            w="100%"
            mah="100%"
            bg="swurple"
            c="white"
            id={containerUUID}
          >
            <Overlay
              avatarImageUrl={authorPhoto}
              vendorDisplayName={displayName}
            />
            <FeedImage
              primaryMedia={mediaAttachment}
              linkedProfile={message?.author}
              vendorId={message?.author?.id}
            />
            <Stack
              h="100%"
              w="100%"
              mah="100%"
              gap={0}
              justify="center"
              align="center"
              style={{ paddingTop: `var(--app-shell-header-height)` }}
            >
              <Center pb={80}>
                <Card
                  shadow="sm"
                  radius="md"
                  withBorder
                  bg="rgba(255, 255, 255, .8)"
                  maw="80%"
                  c="white"
                >
                  <Card.Section p={20} bg="rgba(0,0,0,.6)">
                    <Popover width="target">
                      <Popover.Target>
                        <Text fw={BOLD} lineClamp={2}>
                          {body}
                        </Text>
                      </Popover.Target>
                      <Popover.Dropdown>
                        <Text>{body}</Text>
                      </Popover.Dropdown>
                    </Popover>
                  </Card.Section>
                  <Group justify="center" align="center">
                    <Divider />
                    <PollInteraction
                      id={id}
                      poll={poll}
                      completedInteraction={(followUpId: string | undefined) =>
                        handleCompletedInteraction(followUpId)
                      }
                    />
                  </Group>
                </Card>
              </Center>
              <FeedCardFooter>
                <Text fw={SEMI_BOLD} size={isMobileViewport ? 'lg' : 'xl'}>
                  {displayName}
                </Text>
                <Divider />
                <FeedCardShowMore>
                  <Text
                    style={{ whiteSpace: 'pre-line' }}
                    size={isMobileViewport ? 'md' : 'lg'}
                  >
                    {body}
                  </Text>
                </FeedCardShowMore>
              </FeedCardFooter>
            </Stack>
          </Box>
          <FollowUpInteraction
            followUpOpened={followUpOpened}
            containerUUID={containerUUID}
            followUpInteraction={
              (poll?.followUpInteraction as
                | InteractionPoll
                | InteractionReply
                | InteractionClick) || undefined
            }
            closeFollowUp={closeFollowUp}
            handleCompletedInteraction={handleCompletedInteraction}
          />
        </>
      )}
    </>
  )
}
