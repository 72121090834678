import { faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Money } from '@graphql'
import { SwayCashIcon } from '@icons/SwayCash'
import { Center, Text } from '@mantine/core'
import { SEMI_BOLD, timeDifference } from '@util/utils'

type InteractionTimeRemainingProps = {
  earningPotential: Money
  expiresAt?: string
}

export const InteractionTimeRemaining = ({
  earningPotential,
  expiresAt,
}: InteractionTimeRemainingProps) => {
  if (!earningPotential?.amount) return null

  const expiresIn = expiresAt ? timeDifference(new Date(expiresAt)) : null

  return (
    <Center>
      <FontAwesomeIcon icon={faClock} className="mr-1 inline-block h-5 w-5" />
      {!expiresAt && (
        <Text component="span" fw={SEMI_BOLD}>
          -- hours -- minutes to earn
        </Text>
      )}
      {expiresIn && (
        <Text component="span" fw={SEMI_BOLD}>
          {`${expiresIn.hours} hours ${expiresIn.minutes} minutes to earn`}
        </Text>
      )}
      <SwayCashIcon className="mx-1 inline-block h-5 w-5" />
      <Text component="span" fw={SEMI_BOLD}>
        {(earningPotential?.amount / 100).toFixed(2)}
      </Text>
    </Center>
  )
}
