import { faCalendar } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RedemptionLimitRate } from '@graphql'
import { Badge } from '@mantine/core'

export function IntervalLimitBadge(limit: RedemptionLimitRate) {
  return (
    <Badge
      color={limit.blocking ? 'red' : 'yellow'}
      variant="filled"
      leftSection={<FontAwesomeIcon className=" h-4 w-4" icon={faCalendar} />}
      p={12}
      mx={12}
    >
      {limit.limit} per {limit.unit.toLocaleLowerCase()}
    </Badge>
  )
}
