import { InteractionReply, useInteractionReplyMutation } from '@graphql'
import { Button, Modal, Stack, Text, Textarea } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@util/notifications/notifications'
import { BOLD } from '@util/utils'
import { useCallback, useState } from 'react'
import { BaseFeedInteraction } from './Interactions.types'

export function ReplyInteraction({
  interaction,
  completedInteraction,
}: BaseFeedInteraction & { interaction: InteractionReply }) {
  const [replyText, setReplyText] = useState('')
  const [completed, setCompleted] = useState(false)
  const [replyModalOpened, { open: openReplyModal, close: closeReplyModal }] =
    useDisclosure(false)

  const [interactionReply, { loading: replyMutationLoading }] =
    useInteractionReplyMutation()

  const handleReplyInteraction = useCallback(async () => {
    if (replyText.trim() === '') return

    await interactionReply({
      variables: {
        interactionId: interaction.id,
        body: replyText,
      },
      onCompleted: (response) => {
        if (response.interactionReply.successful) {
          setCompleted(true)
          closeReplyModal()
          notifications.show({
            color: 'green',
            title: 'Reply Submitted',
            message: 'Your reply has been submitted successfully!',
          })
          completedInteraction(
            response.interactionReply.result?.followUpInteraction?.id ??
              undefined
          )
        } else {
          notifications.show({
            color: 'red',
            title: 'Error submitting reply',
            message: 'Please try again later.',
          })
        }
      },
      onError: (error) => {
        notifications.show({
          color: 'red',
          title: 'Error submitting reply',
          message: error.message,
        })
      },
    })
  }, [
    closeReplyModal,
    completedInteraction,
    interaction.id,
    interactionReply,
    replyText,
  ])

  return (
    <>
      {!completed ? (
        <Stack>
          <Button onClick={openReplyModal} fullWidth>
            Reply
          </Button>
        </Stack>
      ) : (
        <Text fw={BOLD}>Thank you for your reply!</Text>
      )}

      <Modal
        opened={replyModalOpened}
        onClose={closeReplyModal}
        title={interaction.ctaDetails || 'Submit Your Reply'}
        centered
      >
        <Stack>
          <Textarea
            placeholder="Type your reply..."
            minRows={3}
            maxLength={500}
            value={replyText}
            onChange={(event) => setReplyText(event.currentTarget.value)}
            disabled={replyMutationLoading}
          />
          <Button
            onClick={handleReplyInteraction}
            loading={replyMutationLoading}
            disabled={replyText.trim() === ''}
          >
            Submit Reply
          </Button>
        </Stack>
      </Modal>
    </>
  )
}
