import {
  MediaPointer,
  Redemption,
  RedemptionDonationProgress,
  useFeedGetSingleRedemptionQuery,
  useRedeemMutation,
  useWalletBalanceQuery,
} from '@graphql'
import {
  Box,
  Button,
  Divider,
  Drawer,
  Group,
  Loader,
  Stack,
  Text,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { RedemptionDonationSection } from '@pages/redemptions/components/RedemptionDonationSection'

import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { useSaveRedemption } from '@hooks/useSaveRedemption'
import { SwayCashIcon } from '@icons/SwayCash'
import { IMGIX_FEED_CONSTANTS } from '@pages/feed/constants/imgixFeed.constants'
import { generateImgixOptions } from '@util/imgixUtils'
import { notifications } from '@util/notifications/notifications'
import { isRedemptionAvailable } from '@util/redemptionsUtils'
import { BOLD, SEMI_BOLD } from '@util/utils'
import { useNavigate } from 'react-router-dom'
import { FeedCardFooter } from '../FeedCardFooter'
import { FeedCardShowMore } from '../FeedCardShowMore'
import { FeedImage } from '../FeedImage'
import { FeedRedemptionLimits } from '../FeedRedemptionLimits'
import { Overlay } from '../Overlay'

export function RedemptionSuggestion({
  id,
  skip,
}: {
  id: string
  skip: () => void
}) {
  const { data, loading } = useFeedGetSingleRedemptionQuery({
    variables: {
      id,
      evaluate: true,
      imgixOpts: generateImgixOptions({
        auto: 'compress',
        ...IMGIX_FEED_CONSTANTS,
      }),
    },
  })
  const { handleSaveRedemption, handleUnsaveRedemption } = useSaveRedemption(id)
  const { data: walletBalanceData } = useWalletBalanceQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })
  const [redeemRedemption, { loading: redeemIsLoading }] = useRedeemMutation()

  const isMobileViewport = useIsMobileViewport()
  const navigate = useNavigate()
  const [
    redeemConfirmationOpened,
    { open: openRedeemConfirmation, close: closeRedeemConfirmation },
  ] = useDisclosure(false)

  const redemption = data?.getRedemption

  const authorProfile = redemption?.vendor
  const hasExpired = !isRedemptionAvailable(redemption as Redemption)

  const vendorDisplayName = redemption?.vendor?.displayName

  const hasBalanceToRedeem = !!(
    walletBalanceData?.wallet?.balance || 0 >= (redemption?.price?.amount || 0)
  )

  const redemptionCostFormatted = `${(
    (redemption?.price.amount || 0) / 100
  ).toFixed(2)} SC`

  const handleRedemptionClick = () => {
    if (redemption?.isNonProfit && hasBalanceToRedeem && !hasExpired) {
      openRedeemConfirmation()
    } else {
      navigate(`/redemptions/offers/${redemption?.id}`)
    }
  }

  const handleRedemptionDonation = () => {
    if (!redemption) return

    redeemRedemption({
      variables: {
        redemptionId: redemption.id,
      },
      onCompleted: (result) => {
        if (!result.redeem.successful) {
          notifications.show({
            title: 'Something went wrong with Redeeming Redemption',
            message: "We couldn't redeem your redemption. Please try again.",
          })
          return
        }

        notifications.show({
          title: 'Donation successful',
          message: `You have successfully donated ${redemptionCostFormatted}`,
        })

        closeRedeemConfirmation()
        skip()
      },
      onError: (error) => {
        notifications.show({
          title: 'Something went wrong with Redeeming Redemption',
          message: error.message,
        })
      },
    })
  }

  return (
    <>
      {loading ? (
        <Loader color="swurple" size="xl" type="dots" />
      ) : (
        <Box w="100%" h="100%" c="white" pos="relative">
          <Overlay
            avatarImageUrl={
              // redemption?.linkedProfile?.profilePhotoUrl || <-- TODO: Need to chat about this later
              authorProfile?.photoUrl
            }
            saved={!!redemption?.saved}
            vendorDisplayName={vendorDisplayName || undefined}
            handleUnsave={handleUnsaveRedemption}
            handleSave={handleSaveRedemption}
          />
          <FeedImage
            primaryMedia={
              redemption?.primaryMedia as MediaPointer | null | undefined
            }
            linkedProfile={redemption?.linkedProfile}
            vendorId={redemption?.vendor?.id}
          />
          <FeedRedemptionLimits
            limits={redemption?.limits?.limits}
            validEnd={redemption?.validEnd}
          />

          <Box id={`redemptionFeedCard-${redemption?.id}`} bottom={0}>
            <FeedCardFooter>
              <RedemptionText
                description={redemption?.description}
                donationProgress={
                  redemption?.donationProgress as
                    | RedemptionDonationProgress
                    | undefined
                }
                isNonProfit={redemption?.isNonProfit}
                name={redemption?.name}
                price={redemption?.price.amount}
                vendorDisplayName={authorProfile?.name}
              />
              <ActionButtons
                hasBalanceToRedeem={hasBalanceToRedeem}
                hasExpired={hasExpired}
                isNonProfit={!!redemption?.isNonProfit}
                redemptionCostFormatted={redemptionCostFormatted}
                handleRedemptionClick={handleRedemptionClick}
              />
            </FeedCardFooter>

            <Drawer.Root
              opened={redeemConfirmationOpened}
              onClose={closeRedeemConfirmation}
              position="bottom"
              size="15%"
              portalProps={{
                target: `#redemptionFeedCard-${redemption?.id}`,
              }}
            >
              <Drawer.Overlay backgroundOpacity={0.5} blur={4} pos="absolute" />
              <Drawer.Content
                style={{
                  width: '100%',
                  position: 'absolute',
                  height: 'auto',
                }}
              >
                <Drawer.Body h="100%">
                  <Stack justify="center" align="center" h="100%">
                    <Button
                      radius="sm"
                      fullWidth
                      onClick={handleRedemptionDonation}
                      disabled={redeemIsLoading}
                      size={isMobileViewport ? 'compact-md' : 'lg'}
                    >
                      Confirm ${redemptionCostFormatted} Donation
                    </Button>
                    <Button
                      radius="sm"
                      c="deep-blue"
                      color="deep-blue"
                      fullWidth
                      variant="outline"
                      onClick={closeRedeemConfirmation}
                      size={isMobileViewport ? 'compact-md' : 'lg'}
                    >
                      Maybe Later
                    </Button>
                  </Stack>
                </Drawer.Body>
              </Drawer.Content>
            </Drawer.Root>
          </Box>
        </Box>
      )}
    </>
  )
}

function ActionButtons({
  handleRedemptionClick,
  redemptionCostFormatted,
  isNonProfit,
  hasBalanceToRedeem,
  hasExpired,
}: {
  hasBalanceToRedeem: boolean
  hasExpired: boolean
  redemptionCostFormatted: string
  isNonProfit: boolean
  handleRedemptionClick: () => void
}) {
  const isMobileViewport = useIsMobileViewport()
  return (
    <Group wrap="nowrap" gap={8} justify="space-between">
      <Button
        onClick={handleRedemptionClick}
        fullWidth
        size={isMobileViewport ? 'compact-md' : 'lg'}
        radius="sm"
      >
        {isNonProfit && hasBalanceToRedeem && !hasExpired
          ? `Donate $${redemptionCostFormatted}`
          : 'View'}
      </Button>
    </Group>
  )
}

function RedemptionText({
  description,
  donationProgress,
  isNonProfit,
  name,
  price,
  vendorDisplayName,
}: {
  description: string | undefined
  donationProgress: RedemptionDonationProgress | undefined
  isNonProfit: boolean | undefined
  name: string | undefined
  price: number | undefined | null
  vendorDisplayName: string | undefined
}) {
  const isMobileViewport = useIsMobileViewport()
  return (
    <Stack gap={4}>
      <Text fw={SEMI_BOLD} size={isMobileViewport ? 'lg' : 'xl'}>
        {vendorDisplayName}
      </Text>
      <Group w="100%" justify="space-between">
        <Text lineClamp={1} size={isMobileViewport ? 'md' : 'lg'}>
          {name}
        </Text>
        {price !== undefined && price !== null && (
          <Group justify="center" align="center" gap={2}>
            <Text span fw={BOLD}>
              {(price / 100).toFixed(2)}
            </Text>
            <Box
              style={{
                padding: '1px',
                borderRadius: '500px',
                backgroundColor: '#fff',
                lineHeight: '0',
                marginBottom: '2px',
              }}
            >
              <SwayCashIcon className="inline-block h-5 w-5 align-middle" />
            </Box>
          </Group>
        )}
      </Group>

      {isNonProfit && (
        <>
          <RedemptionDonationSection
            donationProgress={donationProgress}
            isForDetailsPage={isMobileViewport ? false : true}
          />
          <Divider />
        </>
      )}
      <FeedCardShowMore>
        <Text
          size={isMobileViewport ? 'md' : 'lg'}
          style={{ whiteSpace: 'pre-line' }}
        >
          {description}
        </Text>
      </FeedCardShowMore>
    </Stack>
  )
}
