import {
  InteractionPoll,
  PollOption,
  PollTextOption,
  useInteractionRespondToPollMutation,
} from '@graphql'
import { useBreakpointHeight } from '@hooks/useBreakpoint'
import { SwayCashIcon } from '@icons/SwayCash'
import { Box, Button, Center, Modal, Stack, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { notifications } from '@util/notifications/notifications'
import { BOLD } from '@util/utils'
import { useCallback, useState } from 'react'
import Confetti from 'react-confetti'
import { BaseFeedInteraction } from './Interactions.types'

export function PollInteraction({
  poll,
  completedInteraction,
}: BaseFeedInteraction & { poll: InteractionPoll }) {
  const [modalDetails, setModalDetails] = useState<{
    chosenOption: string
  } | null>(null)
  const [opened, { open, close }] = useDisclosure(false)
  const [completed, setCompleted] = useState(false)
  const isSmallScreen = useBreakpointHeight('max', 'sm')

  const [
    interactionRespondToPoll,
    { loading: interactionRespondToPollLoading },
  ] = useInteractionRespondToPollMutation()

  const handleOnPollOptionSelected = useCallback(
    async (option: PollOption, interactionId: string) => {
      if (interactionRespondToPollLoading) return

      const optionText = (option as PollTextOption).text

      await interactionRespondToPoll({
        variables: {
          pollId: interactionId,
          pollOptionId: option.id,
        },
        onCompleted: (response) => {
          if (response.interactionRespondToPoll.successful) {
            setModalDetails({ chosenOption: optionText })
            open()
            setCompleted(true)

            setTimeout(() => {
              // Move to next card
              completedInteraction(
                response.interactionRespondToPoll.result?.followUpInteraction
                  ?.id ?? undefined
              )
              close()
            }, 2000)
          } else {
            notifications.show({
              color: 'red',
              title: 'Error responding to poll',
              message: 'Please try again later',
            })
          }
        },
        onError: (error) => {
          notifications.show({
            color: 'red',
            title: 'Error responding to poll',
            message: `${error.message}`,
          })
        },
      })
    },
    [
      close,
      completedInteraction,
      interactionRespondToPoll,
      interactionRespondToPollLoading,
      open,
    ]
  )

  const selectedButtonOption = {
    bg: 'swurple',
    c: 'white',
  }

  return (
    <>
      <>
        {poll.options.map((option, optionIdx) => (
          <Button
            disabled={completed || interactionRespondToPollLoading}
            size={isSmallScreen ? 'compact-sm' : 'md'}
            fullWidth
            key={`${option?.id}-${optionIdx}`}
            onClick={() =>
              option?.id ? handleOnPollOptionSelected(option, poll.id) : null
            }
            {...(completed &&
              option?.id == poll.userAnswer?.id &&
              selectedButtonOption)}
          >
            {(option as PollTextOption).text}
          </Button>
        ))}
      </>
      <Modal
        opened={opened}
        onClose={close}
        radius={0}
        centered
        transitionProps={{ transition: 'fade', duration: 200 }}
        withCloseButton={false}
        styles={{
          header: {
            background: 'green',
          },
          content: {
            background: 'green',
          },
        }}
      >
        <Stack justify="center">
          <Center>
            <Box style={{ borderRadius: 5000 }} bg="white" p={4}>
              <SwayCashIcon className="h-10 w-10" />
            </Box>
          </Center>

          <Text size="xl" fw={BOLD} c="white" ta="center">
            You{' '}
            {modalDetails && (
              <>
                <Text px={2} fw={BOLD} span>
                  chose
                </Text>
                <Text px={2} span>
                  {modalDetails?.chosenOption}
                </Text>
                <Text px={2} fw={BOLD} span>
                  and
                </Text>
              </>
            )}
            <Text px={2} span fw={BOLD}>
              earned{' '}
              {poll?.earningPotential?.amount
                ? `${(poll.earningPotential?.amount / 100).toFixed(2)} SwayCash`
                : 'SwayCash'}
              !
            </Text>
          </Text>
        </Stack>
        {opened && <Confetti initialVelocityY={40} />}
      </Modal>
    </>
  )
}
