import {
  InteractionClick,
  InteractionPoll,
  InteractionReply,
  Message,
  useFeedGetSingleInteractionQuery,
} from '@graphql'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { Box, Divider, Loader, Stack, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IMGIX_FEED_CONSTANTS } from '@pages/feed/constants/imgixFeed.constants'
import { generateImgixOptions } from '@util/imgixUtils'
import { SEMI_BOLD } from '@util/utils'
import { uniqueId } from 'lodash-es'
import { useCallback, useState } from 'react'
import { FeedCardFooter } from '../FeedCardFooter'
import { FeedImage } from '../FeedImage'
import { Overlay } from '../Overlay'
import { FollowUpInteraction } from './FollowUp.interaction'
import { ReplyInteraction } from './Reply.interaction'

export function ReplyInteractionContainer({
  id,
  scrollToNextItem,
}: {
  id: string
  scrollToNextItem: () => void
}) {
  const isMobileViewport = useIsMobileViewport()
  const [containerUUID] = useState<string>(uniqueId('interactionContainer-'))
  const [followUpOpened, { open: openFollowUp, close: closeFollowUp }] =
    useDisclosure(false)

  const { data, loading } = useFeedGetSingleInteractionQuery({
    variables: {
      id,
      imgixOpts: generateImgixOptions({
        auto: 'compress',
        ...IMGIX_FEED_CONSTANTS,
      }),
    },
    fetchPolicy: 'network-only',
  })

  const interaction = data?.oneInteraction as InteractionReply
  const message = (interaction as any)?.replyMessage as Message

  const displayName = message?.author?.displayName
  const authorPhoto = message?.author?.profilePictureUrl || undefined
  const body = message?.body
  const mediaAttachment = message?.attachments?.[0]

  const handleCompletedInteraction = useCallback(
    (followUpId: string | undefined) => {
      if (followUpId) {
        openFollowUp()
      } else {
        closeFollowUp()
        scrollToNextItem()
      }
    },
    [closeFollowUp, openFollowUp, scrollToNextItem]
  )

  return (
    <>
      {loading ? (
        <Loader color="swurple" size="xl" variant="dots" />
      ) : (
        <>
          <Box
            h="100%"
            w="100%"
            pos="relative"
            bg="swurple"
            c="white"
            id={containerUUID}
          >
            <Overlay
              avatarImageUrl={authorPhoto}
              vendorDisplayName={displayName}
            />
            <FeedImage
              primaryMedia={mediaAttachment}
              linkedProfile={message?.author}
              vendorId={message?.author?.id}
            />

            <FeedCardFooter>
              <Stack gap={4}>
                <Text fw={SEMI_BOLD} size={isMobileViewport ? 'lg' : 'xl'}>
                  {displayName}
                </Text>
                <Divider />
                <Text
                  style={{ whiteSpace: 'pre-line' }}
                  size={isMobileViewport ? 'md' : 'lg'}
                >
                  {body}
                </Text>
                <ReplyInteraction
                  id={id}
                  interaction={interaction}
                  completedInteraction={(followUpId: string | undefined) =>
                    handleCompletedInteraction(followUpId)
                  }
                />
              </Stack>
            </FeedCardFooter>
          </Box>

          <FollowUpInteraction
            followUpOpened={followUpOpened}
            containerUUID={containerUUID}
            followUpInteraction={
              (interaction?.followUpInteraction as
                | InteractionPoll
                | InteractionReply
                | InteractionClick) || undefined
            }
            closeFollowUp={closeFollowUp}
            handleCompletedInteraction={handleCompletedInteraction}
          />
        </>
      )}
    </>
  )
}
