import { InputLabelSectionHeader } from '@components/InputLabelSectionHeader/InputLabelSectionHeader'
import ImageCropperModal from '@components/media/ImageCropperModal'
import { handleCropComplete } from '@components/media/MediaUtils'
import { MessageRedemptionProps } from '@components/messages/Interactions/MessageRedemption'
import useUploadMediaContext from '@context/mediaContext/useUploadMediaContext'
import {
  faCircleXmark,
  faClose,
  faEdit,
  faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Broadcast,
  BroadcastInput,
  BroadcastStatus,
  BroadcastTarget,
  MediaType,
  MessageTemplate,
  MessageType,
  PageFilterOp,
  RedemptionQueryAttributes,
  useBroadcastPublishMutation,
  useBroadcastTargetsQuery,
  useBroadcastUpdateMutation,
  useBroadcastsQuery,
  useMediaPointerLazyQuery,
  useProfileLocationsQuery,
  useRedemptionsQuery,
  useUserQuery,
} from '@graphql'
import { SwayCashIcon } from '@icons/SwayCash'
import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Card,
  Center,
  Checkbox,
  Collapse,
  ComboboxData,
  Group,
  Image,
  Indicator,
  LoadingOverlay,
  Modal,
  MultiSelect,
  Progress,
  Select,
  Stack,
  Switch,
  Text,
  TextInput,
  Textarea,
} from '@mantine/core'
import { DateTimePicker } from '@mantine/dates'
import { zodResolver } from '@mantine/form'
import { useDebouncedValue, useDisclosure } from '@mantine/hooks'
import { generateImgixOptions } from '@util/imgixUtils'
import { notifications } from '@util/notifications/notifications'
import { SEMI_BOLD, milesToKm } from '@util/utils'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Point } from 'react-easy-crop'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import {
  BroadcastFormProvider,
  BroadcastFormValues,
  isMessageTemplateDefault,
  useBroadcastForm,
} from './BroadcastContext'
import { BroadcastFollowUpMessage } from './BroadcastFollowUpMessage'
import { EditFormBroadcastMessagePreview } from './BroadcastMessagePreview'
import { BroadcastTargetAudience } from './BroadcastTargetAudience'
import { EditBroadcastPublishConfirmationModal } from './EditBroadcastPublishConfirmationModal'
import { EditBroadcastSendImmediatelyConfirmationModal } from './EditBroadcastSendImmediatelyConfirmationModal'
import { EditFormMultipleChoiceOptions } from './EditFormMultipleChoiceOptions'

const MAX_BODY_MESSAGE_SIZE = 500

const newBroadcastValidation = z.object({
  broadcastName: z
    .string()
    .min(1, { message: 'Please enter a broadcast name' })
    .max(100, { message: 'Broadcast name must be less than 100 characters' }),
  messageTemplate: z.object({
    body: z
      .string()
      .min(1, { message: 'Please enter a message body' })
      .max(MAX_BODY_MESSAGE_SIZE, {
        message: `Message body must be less than ${MAX_BODY_MESSAGE_SIZE} characters`,
      }),
    type: z.enum([MessageType.View, MessageType.Reply], {
      errorMap: (_error) => {
        return {
          message: 'Please select a message type',
        }
      },
    }),
    options: z.array(z.object({ text: z.string() })).optional(),
  }),
  sendImmediately: z.boolean(),
})

function convertBroadcastFormValuesToBroadcastInput(
  values: BroadcastFormValues,
  shouldDeleteFollowUpMessageTemplate = false
): BroadcastInput {
  const { messageTemplate, followUpMessageTemplate, ...rest } = values
  // The default data for the template `body` is an empty string, and the backend has validations for this.
  // So in case we are using a default template, we pass `null` to prevent the validation.
  const parsedFollowUpTemplate = isMessageTemplateDefault(
    followUpMessageTemplate as MessageTemplate
  )
    ? null
    : followUpMessageTemplate

  const { templateRedemptionOffers, ...parsedTemplate } = messageTemplate

  return {
    ...rest,
    deleteFollowUpMessageTemplate: shouldDeleteFollowUpMessageTemplate,
    followUpMessageTemplate: parsedFollowUpTemplate,
    messageTemplate: {
      ...parsedTemplate,
      redemptionOffers: {
        offers: templateRedemptionOffers.map((redemptionId) => ({
          redemptionId,
        })),
      },
    },
  }
}

export const EditBroadcastForm = ({
  attachedBroadcastImage,
  broadcast,
  broadcastId,
  isBroadcastMultipleChoice = false,
  loadingBroadcast,
}: {
  broadcast: BroadcastInput | undefined
  broadcastId: string | undefined
  isBroadcastMultipleChoice?: boolean
  attachedBroadcastImage:
    | {
        attachmentId: string
        url: string
      }
    | undefined
  loadingBroadcast: boolean
}) => {
  const [showMessagePreviewModal, setShowMessagePreviewModal] = useState(false)
  const [radiusMiles, setRadiusMiles] = useState<number | null>(null)
  const [showBroadcastFollowUp, setShowBroadcastFollowUp] = useState(false)
  const [debouncedRadiusMiles] = useDebouncedValue(radiusMiles, 600)
  const [messageTypeSelectValue, setMessageTypeSelectValue] = useState<
    string | null | undefined
  >('')
  const [showSendImmediatelyConfirmation, setShowSendImmediatelyConfirmation] =
    useState(false)
  const [
    showScheduleBroadcastConfirmation,
    setShowScheduleBroadcastConfirmation,
  ] = useState(false)

  const [mediaFocalPoint, setMediaFocalPoint] = useState<
    | {
        x: number
        y: number
        z: number
      }
    | undefined
  >(undefined)

  const [crop, setCrop] = useState<Point>({ x: 0.5, y: 0.5 })
  const [zoom, setZoom] = useState(1)

  const [imageModalOpened, { open: imageModalOpen, close: imageModalClose }] =
    useDisclosure(false)

  const { attachedMedia, clearAttachedMedia } = useUploadMediaContext()
  const navigate = useNavigate()

  const [updateBroadcast, { loading: updateBroadcastLoading }] =
    useBroadcastUpdateMutation()
  const [publishBroadcast, { loading: publishBroadcastLoading }] =
    useBroadcastPublishMutation()

  const { data: userQueryData } = useUserQuery({
    fetchPolicy: 'cache-first',
  })
  const { data } = useBroadcastsQuery({
    fetchPolicy: 'cache-first',
  })

  const broadcasts = data?.broadcasts?.data

  const { data: broadcastTargetsData, loading: broadcastTargetsLoading } =
    useBroadcastTargetsQuery({
      variables: {
        query: {
          broadcastTarget: debouncedRadiusMiles
            ? {
                allFollowers: false,
                radiusKm: milesToKm(debouncedRadiusMiles),
                usePrimaryLocation: true,
              }
            : { allFollowers: true },
          pageSize: 1,
        },
      },
    })

  const { data: profileLocationsData } = useProfileLocationsQuery()

  const { data: redemptionsData } = useRedemptionsQuery({
    variables: {
      query: {
        limit: 100,
        filters: [
          {
            attribute: RedemptionQueryAttributes.ArchivedAt,
            op: PageFilterOp.Empty,
            value: { boolean: true },
          },
          {
            attribute: RedemptionQueryAttributes.Active,
            op: PageFilterOp.Eq,
            value: { boolean: true },
          },
        ],
      },
      imgixOpts: generateImgixOptions({
        w: 256,
        h: 256,
        fit: 'crop',
        auto: 'compress',
      }),
    },
  })

  const [
    getMediaPointer,
    {
      loading: loadingMediaPointer,
      error: errorMediaPointer,
      data: mediaPointerData,
    },
  ] = useMediaPointerLazyQuery()

  const [localMediaPointerData, setLocalMediaPointerData] =
    useState(mediaPointerData)

  useEffect(() => {
    if (!mediaPointerData || errorMediaPointer || loadingMediaPointer) return
    setLocalMediaPointerData(mediaPointerData)
  }, [mediaPointerData, errorMediaPointer, loadingMediaPointer])

  const clearMediaPointerData = () => {
    setLocalMediaPointerData(undefined)
  }

  const redemptions = redemptionsData?.redemptions?.data
  const redemptionOptionsMap = new Map(
    redemptions?.map((redemption) => [redemption.id, redemption]) || []
  )
  const redemptionOptions: ComboboxData =
    redemptionsData?.redemptions?.data?.map((redemption) => ({
      label: redemption.name,
      value: redemption.id,
    })) || []

  const totalNumberOfBroadcastTargets =
    broadcastTargetsData?.broadcastTargets?.page?.totalCount

  const form = useBroadcastForm({
    initialValues: {
      broadcastTarget: null,
      broadcastName: '',
      broadcastStart: new Date(),
      followUpMessageTemplate: {
        body: '',
        options: [],
      },
      messageTemplate: {
        body: '',
        type: null,
        price: 100,
        templateRedemptionOffers: [],
        options: [],
        primaryAttachmentId: attachedBroadcastImage?.attachmentId || null,
        primaryAttachmentDefaultMediaOptions: null,
      },
      sendImmediately: false,
      isPersistent: true,
    },
    validate: zodResolver(newBroadcastValidation),
  })

  const [isMultipleChoice, setIsMultipleChoice] = useState(false)

  useEffect(() => {
    setIsMultipleChoice(isBroadcastMultipleChoice)
  }, [isBroadcastMultipleChoice])

  const handleMessageTypeChange = (e: string | null) => {
    switch (e) {
      case 'View':
        {
          form.setFieldValue('messageTemplate.type', MessageType.View)
          form.setFieldValue('messageTemplate.options', [])
          setIsMultipleChoice(false)
        }
        break
      case 'Reply':
        {
          form.setFieldValue('messageTemplate.type', MessageType.Reply)
          form.setFieldValue('messageTemplate.options', [])
          setIsMultipleChoice(false)
        }
        break
      case 'Multiple-choice':
        {
          form.setFieldValue('messageTemplate.type', MessageType.Reply)
          form.setFieldValue('messageTemplate.options', [{ text: '' }])
          setIsMultipleChoice(true)
        }
        break
      default: {
        form.setFieldValue('messageTemplate.type', null)
        setIsMultipleChoice(false)
      }
    }
  }

  const handleAddOption = () => {
    form.insertListItem('messageTemplate.options', {
      text: '',
    })
  }

  const handleRemoveOption = (index: number) => {
    form.removeListItem('messageTemplate.options', index)
  }

  function handleFormSubmission(values: BroadcastFormValues) {
    if (values.sendImmediately) {
      setShowSendImmediatelyConfirmation(true)
      return
    } else if (!values.sendImmediately && values.broadcastStart) {
      setShowScheduleBroadcastConfirmation(true)
    } else {
      handleUpdateDraft(values)
    }
  }

  function broadcastErrorToast() {
    notifications.show({
      title: 'Broadcast Error',
      message:
        'There was an error creating your broadcast. Please refresh the page. If the problem persists, please contact support.',
      color: 'red',
    })
    return
  }

  async function handleSendImmediately(values: BroadcastFormValues) {
    const broadcastStart = values.sendImmediately ? null : values.broadcastStart
    // ? If there is no broadcastId, either the page is being used improperly, or they got here impossibly
    if (!broadcastId) {
      broadcastErrorToast()
      return
    }

    const valid = form.validate()

    if (valid.hasErrors) {
      notifications.show({
        title: 'Form Incomplete',
        message: 'Please update the field(s) marked in red above',
        color: 'red',
      })

      if (valid.errors && Object.keys(valid.errors).length > 0) {
        // Handle the case of keys containig '.' (ex: messageTemplate.body)
        const errorKey = Object.keys(valid.errors)[0]

        const parsedErrorKey = errorKey.replace(/\./g, '\\.')

        const elementWithError = document.querySelector(`#${parsedErrorKey}`)

        if (elementWithError) {
          elementWithError.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }

      return
    }

    try {
      const updatedBroadcastInput =
        convertBroadcastFormValuesToBroadcastInput(values)

      const updatedBroadcast = await updateBroadcast({
        variables: {
          broadcast: {
            ...updatedBroadcastInput,
            messageTemplate: {
              ...updatedBroadcastInput.messageTemplate,
              primaryAttachmentDefaultMediaOptions: generateImgixOptions({
                'fp-x': mediaFocalPoint?.x,
                'fp-y': mediaFocalPoint?.y,
                'fp-z': mediaFocalPoint?.z,
                fit: 'crop',
                crop: 'focalpoint',
                ar: '5:3',
              }),
            },
            broadcastStart,
          },
          broadcastId,
        },
      })

      if (updatedBroadcast.data?.broadcastUpdate?.successful === false) {
        notifications.show({
          title: 'Broadcast Error',
          message: 'There was an error creating your broadcast',
          color: 'red',
        })

        // Shortcircuit
        return
      }

      const publishedBroadcast = await publishBroadcast({
        variables: {
          broadcastId,
        },
      })

      if (publishedBroadcast.data?.broadcastPublish?.successful) {
        notifications.show({
          title: 'Broadcast Sent',
          message: 'Your broadcast has been sent',
          color: 'green',
        })
        navigate('/vendor/broadcasts')
      }
    } catch (e) {
      console.error('Error publishing broadcast: ', e)
      notifications.show({
        title: 'Broadcast Error',
        message: 'There was an error publishing your broadcast',
        color: 'red',
      })
    }
  }

  async function handleOnPublish(values: BroadcastFormValues) {
    // ? If there is no broadcastId, either the page is being used improperly, or they got here impossibly
    if (!broadcastId) {
      broadcastErrorToast()
      return
    }

    const updatedBroadcastInput =
      convertBroadcastFormValuesToBroadcastInput(values)

    try {
      const updatedBroadcast = await updateBroadcast({
        variables: {
          broadcast: {
            ...updatedBroadcastInput,
            messageTemplate: {
              ...updatedBroadcastInput.messageTemplate,
              primaryAttachmentDefaultMediaOptions: generateImgixOptions({
                'fp-x': mediaFocalPoint?.x,
                'fp-y': mediaFocalPoint?.y,
                'fp-z': mediaFocalPoint?.z,
                fit: 'crop',
                crop: 'focalpoint',
                ar: '5:3',
              }),
            },
          },
          broadcastId,
        },
      })

      if (updatedBroadcast.data?.broadcastUpdate?.successful === false) {
        notifications.show({
          title: 'Broadcast Error',
          message: 'There was an error creating your broadcast',
          color: 'red',
        })

        // Shortcircuit
        return
      }

      if (
        updatedBroadcast.data?.broadcastUpdate?.result?.status ===
        BroadcastStatus.Scheduled
      ) {
        notifications.show({
          title: 'Broadcast Scheduled',
          message: `Your broadcast has been updated and is scheduled to send on ${dayjs(
            updatedBroadcastInput.broadcastStart
          ).format('DD MMM YYYY hh:mm A')}`,
          color: 'green',
        })
        // Shortcircuit
        return
      }

      const publishedBroadcast = await publishBroadcast({
        variables: {
          broadcastId,
        },
      })

      if (publishedBroadcast.data?.broadcastPublish?.successful === false) {
        notifications.show({
          title: 'Broadcast Error',
          message: 'There was an error publishing your broadcast',
          color: 'red',
        })

        // Shortcircuit
        return
      }

      if (publishedBroadcast.data?.broadcastPublish?.successful) {
        if (values.sendImmediately) {
          notifications.show({
            title: 'Broadcast Scheduled',
            message: `Your broadcast has been scheduled to send on ${dayjs(
              updatedBroadcastInput.broadcastStart
            ).format('DD MMM YYYY hh:mm A')}`,
            color: 'green',
          })
        } else {
          notifications.show({
            title: 'Broadcast Sent',
            message: 'Your broadcast has been sent',
            color: 'green',
          })
        }

        navigate('/vendor/broadcasts')
      }
    } catch (e) {
      console.error('Error publishing broadcast: ', e)
      notifications.show({
        title: 'Broadcast Error',
        message: 'There was an error publishing your broadcast',
        color: 'red',
      })
    }
  }

  async function handleUpdateDraft(values: BroadcastFormValues) {
    if (!broadcastId) {
      broadcastErrorToast()
      return
    }

    const valid = form.validate()

    if (valid.hasErrors) {
      notifications.show({
        title: 'Form Incomplete',
        message: 'Please update the field(s) marked in red above',
        color: 'red',
      })

      if (valid.errors && Object.keys(valid.errors).length > 0) {
        // Handle the case of keys containig '.' (ex: messageTemplate.body)
        const errorKey = Object.keys(valid.errors)[0]

        const parsedErrorKey = errorKey.replace(/\./g, '\\.')

        const elementWithError = document.querySelector(`#${parsedErrorKey}`)

        if (elementWithError) {
          elementWithError.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }

      return
    }

    if (broadcastId) {
      // We want to delete the current follow up message from the given broadcasts if:
      // * There is an existing follow-up message template in the broadcast.
      // * The follow-up message template from the form is in a default state.

      const currentBroadcastHasFollowUp = !isMessageTemplateDefault(
        broadcast?.followUpMessageTemplate as MessageTemplate
      )
      const formDoesNotHaveFollowUp = isMessageTemplateDefault(
        values.followUpMessageTemplate as MessageTemplate
      )

      const shouldDeleteFollowUpMessageTemplate =
        currentBroadcastHasFollowUp && formDoesNotHaveFollowUp

      const updatedBroadcastInput = convertBroadcastFormValuesToBroadcastInput(
        values,
        shouldDeleteFollowUpMessageTemplate
      )

      const result = await updateBroadcast({
        variables: {
          broadcast: {
            ...updatedBroadcastInput,
            messageTemplate: {
              ...updatedBroadcastInput.messageTemplate,
              primaryAttachmentDefaultMediaOptions: generateImgixOptions({
                'fp-x': mediaFocalPoint?.x,
                'fp-y': mediaFocalPoint?.y,
                'fp-z': mediaFocalPoint?.z,
                fit: 'crop',
                crop: 'focalpoint',
                ar: '5:3',
              }),
            },
            broadcastStart: null,
          },
          broadcastId,
        },
      })

      if (result.data?.broadcastUpdate?.successful) {
        notifications.show({
          title: 'Broadcast Saved',
          message: 'Your broadcast has been saved as a draft',
          color: 'green',
        })
      } else {
        notifications.show({
          title: 'Broadcast Error',
          message: 'There was an error updating your broadcast',
          color: 'red',
        })
      }
    }
  }

  const handleRemoveBroadcastImage = () => {
    form.setFieldValue('messageTemplate.primaryAttachmentId', null)
  }

  useEffect(
    function loadFormValuesFromPropsSideEffects() {
      if (broadcast !== undefined) {
        const messageTemplate = broadcast.messageTemplate
        form.setValues({
          ...broadcast,
          messageTemplate: {
            ...messageTemplate,
            templateRedemptionOffers:
              (messageTemplate?.redemptionOffers?.offers
                ?.map((offer) => offer?.redemptionId)
                .filter((offer) => offer !== undefined) as Array<string>) ||
              ([] as Array<string>),
          },
        })

        form.resetDirty({
          ...broadcast,
          messageTemplate: {
            ...messageTemplate,
            templateRedemptionOffers:
              (messageTemplate?.redemptionOffers?.offers
                ?.map((offer) => offer?.redemptionId)
                .filter((offer) => offer !== undefined) as Array<string>) ||
              ([] as Array<string>),
          },
        })

        setMessageTypeSelectValue(getDefaultMessageType(broadcast))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [broadcast]
  )

  const handleGetMediaPointer = () => {
    getMediaPointer({
      variables: {
        mediaPointerId: attachedMedia[0].mediaPointerId,
        opts9by16: generateImgixOptions({
          w: 360,
          h: 640,
          fit: 'crop',
          auto: 'compress',
          'fp-x': mediaFocalPoint?.x,
          'fp-y': mediaFocalPoint?.y,
          'fp-z': mediaFocalPoint?.z,
          crop: 'focalpoint',
          ar: '9:16',
        }),
        opts5by3: generateImgixOptions({
          w: 500,
          h: 300,
          fit: 'crop',
          auto: 'compress',
          'fp-x': mediaFocalPoint?.x,
          'fp-y': mediaFocalPoint?.y,
          'fp-z': mediaFocalPoint?.z,
          crop: 'focalpoint',
          ar: '5:3',
        }),
      },
    })
  }

  useEffect(
    function handleAttachedMediaUpdateSideEffects() {
      if (attachedMedia?.length > 0) {
        form.setFieldValue(
          'messageTemplate.primaryAttachmentId',
          attachedMedia[0].mediaPointerId
        )
        handleGetMediaPointer()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attachedMedia]
  )

  function getDefaultMessageType(broadcast: BroadcastInput | undefined) {
    if (broadcast === undefined) {
      return undefined
    }

    if (broadcast.messageTemplate?.type === MessageType.Reply) {
      const optionsLength = broadcast.messageTemplate?.options?.length || 0
      return optionsLength > 0 ? 'Multiple-choice' : 'Reply'
    }

    return 'View'
  }

  const messagePreviewRedemptions: Array<MessageRedemptionProps> =
    form.values.messageTemplate.templateRedemptionOffers
      .map((redemptionId) => {
        const redemption = redemptionOptionsMap.get(redemptionId)
        if (!redemption) return null
        return {
          name: redemption.name,
          price: redemption.price.amount || 0,
          thumbnailUrl: redemption?.primaryMedia?.proxy?.url,
          vendorPhotoUrl: redemption.vendor?.photoUrl,
        }
      })
      .filter(
        (redemption) => redemption !== null
      ) as Array<MessageRedemptionProps>

  //  There can only be one primary location per user.
  const profilePrimaryLocation = profileLocationsData?.profileLocations.find(
    (l) => l?.isPrimary
  )

  const searchingTargetMembers =
    broadcastTargetsLoading || radiusMiles !== debouncedRadiusMiles

  function getMaximumSpendForBroadcast(
    broadcastPrice: number,
    totalNumberOfBroadcastTargets: number
  ) {
    const finalPrice = showBroadcastFollowUp
      ? broadcastPrice * 2
      : broadcastPrice

    return ` ${(finalPrice / 100).toFixed(2).toLocaleString()} per message = ${(
      (finalPrice * totalNumberOfBroadcastTargets) /
      100
    )
      .toFixed(2)
      .toLocaleString()} SC`
  }

  const hasLocallyAttachedMedia = attachedMedia && attachedMedia.length > 0
  const attachedMediaThumbnail =
    hasLocallyAttachedMedia && attachedMedia[0].mediaType === MediaType.Image
      ? attachedMedia[0].original.localUrl
      : undefined

  return (
    <Box pos="relative" w="100%">
      <LoadingOverlay visible={loadingBroadcast} zIndex={1000} />
      <BroadcastFormProvider form={form}>
        <form>
          <Stack mb="xl">
            <TextInput
              id="broadcastName"
              label={
                <InputLabelSectionHeader>
                  Broadcast Name
                </InputLabelSectionHeader>
              }
              placeholder="Enter broadcast name"
              required
              {...form.getInputProps('broadcastName')}
            />
            <Box id="form-message-body">
              <Group justify="space-between">
                <Text
                  component="label"
                  htmlFor="messageTemplate.body"
                  size="sm"
                  fw={SEMI_BOLD}
                >
                  Message{' '}
                  <Text component="span" c="red">
                    *
                  </Text>
                </Text>
                <Button
                  variant="transparent"
                  fz="xs"
                  onClick={() => setShowMessagePreviewModal(true)}
                  size="compact-sm"
                >
                  Preview
                </Button>
              </Group>
              <Textarea
                id="messageTemplate.body"
                placeholder="Enter message"
                required
                autosize
                maxRows={5}
                {...form.getInputProps('messageTemplate.body')}
              />
              <Group justify="space-between" mt={4}>
                <Text
                  size="xs"
                  c={
                    (form.values.messageTemplate.body?.length || 0) <
                    MAX_BODY_MESSAGE_SIZE * 0.8
                      ? 'gray'
                      : 'red'
                  }
                  ta="end"
                  w="100%"
                >
                  {form.values.messageTemplate.body?.length || 0} /{' '}
                  {MAX_BODY_MESSAGE_SIZE}
                </Text>
              </Group>
            </Box>
            <Select
              data={['View', 'Reply', 'Multiple-choice']}
              label={
                <InputLabelSectionHeader>Message Type</InputLabelSectionHeader>
              }
              placeholder="Select message type"
              required
              value={messageTypeSelectValue}
              onChange={(e) => {
                handleMessageTypeChange(e)
                setMessageTypeSelectValue(e)
              }}
            />
            <Collapse in={isMultipleChoice}>
              <Card withBorder>
                <Stack>
                  <EditFormMultipleChoiceOptions
                    handleRemoveOption={handleRemoveOption}
                    formOptionsField="messageTemplate.options"
                  />
                  <Button
                    variant="Outline"
                    leftSection={<FontAwesomeIcon icon={faPlus} />}
                    onClick={handleAddOption}
                  >
                    Add Response
                  </Button>
                </Stack>
              </Card>
            </Collapse>
            <MultiSelect
              data={redemptionOptions}
              label={
                <Text
                  size="sm"
                  fw={SEMI_BOLD}
                  display="inline-block"
                  component="label"
                  htmlFor="templateRedemptionOffers"
                >
                  Redemption Offers{' '}
                  <Text component="span" c="dimmed">
                    (Optional)
                  </Text>
                </Text>
              }
              id="templateRedemptionOffers"
              maxDropdownHeight={300}
              placeholder="Select redemption offers"
              hidePickedOptions
              clearable
              clearButtonProps={{
                icon: <FontAwesomeIcon icon={faCircleXmark} />,
              }}
              renderOption={({ option }) => {
                const redemption = redemptionOptionsMap.get(option.value)
                if (!redemption) return null

                const thumbnailUrl = redemption?.primaryMedia?.proxy?.url
                return (
                  <Group gap="sm" wrap="nowrap">
                    {thumbnailUrl && (
                      <Image src={thumbnailUrl} h={36} w={36} fit="contain" />
                    )}
                    {!thumbnailUrl && <Avatar size={36} />}
                    <div>
                      <Text size="sm" lineClamp={1}>
                        {redemption.name}
                      </Text>
                      <Text size="xs" lineClamp={1} opacity={0.5}>
                        {redemption.description}
                      </Text>
                    </div>
                  </Group>
                )
              }}
              {...form.getInputProps(
                'messageTemplate.templateRedemptionOffers'
              )}
            />
            <Stack gap={2}>
              <Text
                size="sm"
                fw={SEMI_BOLD}
                display="inline-block"
                component="label"
                htmlFor="templateRedemptionOffers"
              >
                Cover Image{' '}
                <Text component="span" c="dimmed">
                  (Optional)
                </Text>
                {!attachedMediaThumbnail && <BroadcastMediaButtons />}
              </Text>
            </Stack>
            {attachedMedia[0]?.mediaType === MediaType.Image && (
              <Box p={6} maw={150} pos="relative">
                <Image
                  src={attachedMedia[0]?.thumbnail.localUrl}
                  h={150}
                  mah={150}
                  w={150}
                />

                {attachedMedia[0]?.uploadProgress !== 100 && (
                  <Progress
                    value={attachedMedia[0]?.uploadProgress || 0}
                    pos="absolute"
                    bottom={6}
                    w="100%"
                    size="xl"
                    animated={attachedMedia[0]?.uploadProgress !== 100}
                  />
                )}
                {attachedMedia[0]?.uploadProgress === 100 && (
                  <Progress.Root pos="absolute" bottom={6} w="100%" size="xl">
                    <Progress.Section value={100} c="violet">
                      <Progress.Label>Upload Complete!</Progress.Label>
                    </Progress.Section>
                  </Progress.Root>
                )}

                <ActionIcon
                  variant="filled"
                  radius="xl"
                  onClick={() => {
                    clearAttachedMedia()
                    clearMediaPointerData()
                    setMediaFocalPoint(undefined)
                  }}
                  pos="absolute"
                  top={0}
                  right={0}
                >
                  <FontAwesomeIcon icon={faClose} />
                </ActionIcon>
                <ActionIcon
                  variant="filled"
                  radius="xl"
                  onClick={imageModalOpen}
                  pos="absolute"
                  top={0}
                  left={0}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </ActionIcon>
              </Box>
            )}
            {attachedMedia[0]?.mediaType !== MediaType.Image &&
              form.values.messageTemplate.primaryAttachmentId &&
              attachedBroadcastImage?.attachmentId ===
                form.values.messageTemplate.primaryAttachmentId && (
                <Stack p={6} maw={150} pos="relative">
                  <Image
                    src={attachedBroadcastImage.url}
                    h={150}
                    w={150}
                    mah={150}
                  />
                  <ActionIcon
                    variant="filled"
                    radius="xl"
                    onClick={() => {
                      handleRemoveBroadcastImage()
                    }}
                    pos="absolute"
                    top={0}
                    right={0}
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </ActionIcon>
                </Stack>
              )}
            <InputLabelSectionHeader>Message Preview</InputLabelSectionHeader>
            <Card withBorder>
              <EditFormBroadcastMessagePreview
                isMultipleChoice={isMultipleChoice}
                redemptions={messagePreviewRedemptions}
                thumbnailUrl={
                  localMediaPointerData?.mediaPointer.five_by_three?.url
                }
                values={form.values}
              />
            </Card>
            {localMediaPointerData && (
              <>
                <InputLabelSectionHeader>
                  Fullscreen Image Preview
                </InputLabelSectionHeader>
                <Card withBorder>
                  <Image
                    src={
                      localMediaPointerData?.mediaPointer.nine_by_sixteen?.url
                    }
                    w={180}
                    h={320}
                    mah={320}
                    radius="md"
                  />
                </Card>
              </>
            )}
            <BroadcastFollowUpMessage
              setShowBroadcastFollowUp={setShowBroadcastFollowUp}
              showBroadcastFollowUp={showBroadcastFollowUp}
            />
            <BroadcastTargetAudience
              profilePrimaryLocation={profilePrimaryLocation}
              setRadiusMiles={setRadiusMiles}
              formFieldOnChange={form.getInputProps('broadcastTarget').onChange}
              broadcastTarget={
                form.getInputProps('broadcastTarget').value as BroadcastTarget
              }
            />
            <InputLabelSectionHeader>
              Maximum Spend For Broadcast
            </InputLabelSectionHeader>
            <Box h={30}>
              {searchingTargetMembers ? (
                <Text>Calculating total cost of broadcast...</Text>
              ) : totalNumberOfBroadcastTargets !== undefined &&
                totalNumberOfBroadcastTargets !== null &&
                form.values.messageTemplate.price !== undefined &&
                form.values.messageTemplate.price !== null ? (
                <Text>
                  {`${totalNumberOfBroadcastTargets.toLocaleString()} recipients x `}
                  <SwayCashIcon className="inline-block h-4 w-4" />
                  {getMaximumSpendForBroadcast(
                    form.values.messageTemplate.price,
                    totalNumberOfBroadcastTargets
                  )}
                </Text>
              ) : (
                <Text>Unable to calculate total cost of this broadcast.</Text>
              )}
            </Box>
            <InputLabelSectionHeader>
              Schedule Broadcast{' '}
              <Text component="span" c="dimmed">
                (Optional)
              </Text>
            </InputLabelSectionHeader>
            <Text>
              {form.values.isPersistent
                ? 'You can go live now, or schedule this Persistent Broadcast to go live at a future date and time.'
                : 'You can send this Broadcast now, or schedule it to send at a future date and time.'}
            </Text>
            <Checkbox
              label={
                <InputLabelSectionHeader>
                  {form.values.isPersistent
                    ? 'Go Live Now'
                    : 'Send immediately'}
                </InputLabelSectionHeader>
              }
              {...form.getInputProps('sendImmediately')}
              onChange={(e) => {
                form.setFieldValue('sendImmediately', e.target.checked)
                if (e.target.checked) {
                  form.setFieldValue('broadcastStart', new Date())
                } else {
                  form.setFieldValue('broadcastStart', null)
                }
              }}
            />
            <DateTimePicker
              clearable
              description="Dates with scheduled broadcasts have a blue indicator"
              disabled={!!form.values.sendImmediately}
              dropdownType="popover"
              firstDayOfWeek={0}
              label={
                <InputLabelSectionHeader>
                  {form.values.isPersistent ? 'Live at' : 'Send at'}
                </InputLabelSectionHeader>
              }
              placeholder="Pick date and time"
              valueFormat="DD MMM YYYY hh:mm A"
              pointer
              required={!form.values.sendImmediately}
              renderDay={(date) =>
                RenderDateTimeDay(
                  date,
                  form.values,
                  broadcasts as Array<Broadcast> | undefined
                )
              }
              {...form.getInputProps('broadcastStart')}
            />
            <InputLabelSectionHeader>
              Timezone:{' '}
              <Text component="span" c="dark">
                {userQueryData?.user?.profile?.timezone || 'UTC'}
              </Text>
            </InputLabelSectionHeader>

            <Text size="sm" fw={SEMI_BOLD}>
              Persistent
            </Text>
            <Switch
              label="Persistent Broadcast"
              {...form.getInputProps('isPersistent', {
                type: 'checkbox',
              })}
            />

            <Button
              loading={updateBroadcastLoading || publishBroadcastLoading}
              size="lg"
              type="button"
              variant="primary"
              fullWidth
              onClick={() => {
                if (
                  !form.values.sendImmediately &&
                  !form.values.broadcastStart
                ) {
                  handleUpdateDraft(form.values)
                } else {
                  handleFormSubmission(form.values)
                }
              }}
            >
              {form.values.sendImmediately &&
                (form.values.isPersistent ? 'Go Live Now' : 'Send Now')}
              {!form.values.sendImmediately &&
                form.values.broadcastStart &&
                'Schedule for ' +
                  dayjs(form.values.broadcastStart).format(
                    'ddd MMM DD, YYYY hh:mm A'
                  )}
              {!form.values.sendImmediately &&
                !form.values.broadcastStart &&
                'Save Draft'}
            </Button>
            <Button
              size="lg"
              type="button"
              variant="outline"
              fullWidth
              onClick={() => {
                form.reset()
                navigate('/vendor/broadcasts')
              }}
            >
              Cancel
            </Button>
          </Stack>
        </form>
      </BroadcastFormProvider>

      {/* // * Modals */}
      <Modal
        opened={showMessagePreviewModal}
        onClose={() => setShowMessagePreviewModal(false)}
        title="Message Preview"
        size="md"
        w={'100%'}
      >
        <Stack>
          <Card withBorder>
            <EditFormBroadcastMessagePreview
              isMultipleChoice={isMultipleChoice}
              thumbnailUrl={
                localMediaPointerData?.mediaPointer.five_by_three?.url
              }
              values={form.values}
            />
          </Card>
          <Text>Different preview sizes</Text>
          {localMediaPointerData && (
            <Group>
              {/* We are not showing the 1:1 image here because it is not used for broadcasts */}
              <Image
                src={localMediaPointerData.mediaPointer.five_by_three?.url}
                w={500}
                h={300}
                mah={150}
                radius="md"
              />
              <Image
                src={localMediaPointerData.mediaPointer.nine_by_sixteen?.url}
                w={360}
                h={640}
                mah={150}
                radius="md"
              />
            </Group>
          )}
        </Stack>
      </Modal>
      <EditBroadcastSendImmediatelyConfirmationModal
        opened={showSendImmediatelyConfirmation}
        isPersistent={form.values.isPersistent as boolean}
        onConfirmation={() => {
          handleSendImmediately(form.values)
          setShowSendImmediatelyConfirmation(false)
        }}
        onClose={() => setShowSendImmediatelyConfirmation(false)}
      />
      <EditBroadcastPublishConfirmationModal
        opened={showScheduleBroadcastConfirmation}
        publishDate={form.values.broadcastStart}
        onConfirmation={() => {
          handleOnPublish(form.values)
          setShowScheduleBroadcastConfirmation(false)
        }}
        onClose={() => setShowScheduleBroadcastConfirmation(false)}
      />
      <ImageCropperModal
        imageModalOpened={imageModalOpened}
        imageModalClose={imageModalClose}
        attachedMediaThumbnail={attachedMediaThumbnail}
        crop={crop}
        setCrop={setCrop}
        zoom={zoom}
        setZoom={setZoom}
        handleCropComplete={(croppedArea, croppedAreaPixels) =>
          handleCropComplete(
            croppedArea,
            croppedAreaPixels,
            attachedMedia,
            setMediaFocalPoint,
            zoom
          )
        }
        onSave={handleGetMediaPointer}
      />
    </Box>
  )
}

function RenderDateTimeDay(
  date: Date,
  formValues: BroadcastInput,
  broadcasts: Array<Broadcast> | undefined
) {
  const dayIsToday = date.toDateString() === new Date().toDateString()
  const dayIsCurrentlySelected =
    date.toDateString() === new Date(formValues.broadcastStart).toDateString()
  const day = date.getDate()
  const broadcastScheduledOnThisDay = broadcasts?.some((broadcast) => {
    const broadcastScheduledDate =
      broadcast.broadcastStart || broadcast.broadcastPublishedAt
    return (
      new Date(broadcastScheduledDate).toDateString() === date.toDateString() &&
      broadcast.status !== BroadcastStatus.Archived
    )
  })
  return (
    <Center
      bg={dayIsToday && !dayIsCurrentlySelected ? 'teal.1' : 'transparent'}
      w="100%"
      h="100%"
      style={{
        borderRadius: 2,
      }}
    >
      <Indicator
        size={6}
        color="blue"
        offset={-2}
        disabled={!broadcastScheduledOnThisDay}
      >
        <div>{day}</div>
      </Indicator>
    </Center>
  )
}

const BroadcastMediaButtons = () => {
  const { selectMedia } = useUploadMediaContext()

  return (
    <>
      <Button
        variant="outline"
        type="button"
        onClick={() => selectMedia()}
        fullWidth
      >
        Add Photo
      </Button>
      {/* // ! This is commented out because it is not full implemented yet
      <ActionIcon variant="outline" radius="xl">
      <FontAwesomeIcon icon={faCamera} />
      </ActionIcon> */}
    </>
  )
}
