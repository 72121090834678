import { Browser } from '@capacitor/browser'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Badge, Group, Stack, Text, Title } from '@mantine/core'
import { BOLD } from '@util/utils'
import { UnclaimedChip } from './UnclaimedChip'

export type HeroSectionProps = {
  avatarImageUrl: string
  displayName: string
  name: string
  unclaimed: boolean
  verified: boolean
  isNonProfit: boolean
  website?: {
    url: string
    display: string
  }
}

export function HeroSection({
  avatarImageUrl,
  displayName,
  name,
  unclaimed,
  verified,
  website,
  isNonProfit,
}: HeroSectionProps) {
  const handleWebsiteClick = (url: string) => {
    Browser.open({
      url,
    })
  }

  const showingBadges = unclaimed || verified

  const titleOrder = Math.ceil(name.length / 5) as 1 | 2 | 3 | 4

  return (
    <Stack align="center" gap={4}>
      <Avatar src={avatarImageUrl} size={60} />
      <Title order={titleOrder} maw={250} fw={BOLD} ta="center">
        {name} Here
      </Title>
      {displayName && (
        <Text fw={400} ta="center" truncate maw={250}>
          @{displayName}
        </Text>
      )}
      {isNonProfit && (
        <Badge size="lg" color="peach-orange">
          Non-Profit Organization
        </Badge>
      )}
      {website && (
        <Text
          fw={400}
          ta="center"
          className="cursor-pointer"
          onClick={() => handleWebsiteClick(website.url)}
        >
          {website.display}
        </Text>
      )}
      {showingBadges && (
        <Group gap={4} mt={4}>
          {unclaimed && <UnclaimedChip vendorDisplayName={name} />}
          {verified && (
            <Badge
              color="swurple"
              leftSection={<FontAwesomeIcon icon={faCircleCheck} />}
            >
              Verified
            </Badge>
          )}
        </Group>
      )}
    </Stack>
  )
}
