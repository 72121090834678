import { FeedItemNotification, FeedNotificationType } from '@graphql'
import { AllCaughtUp } from './AllCaughtUp.notification'
import { DonationGoalReached } from './DonationGoalReached.notification'

type NotificationFeedItemProps = {
  id: string
  scrollToNextItem: () => void
  feedItem: FeedItemNotification
  containerRef: React.RefObject<HTMLDivElement>
}

export function NotificationFeedItem({
  id,
  scrollToNextItem,
  feedItem,
  containerRef,
}: NotificationFeedItemProps) {
  return (
    <>
      {id === 'all-caught-up' && <AllCaughtUp />}

      {feedItem.notification.type ===
        FeedNotificationType.DonationGoalReached && (
        <DonationGoalReached
          id={id}
          scrollToNextItem={scrollToNextItem}
          notification={feedItem.notification}
          containerRef={containerRef}
        />
      )}
    </>
  )
}
