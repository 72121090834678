import {
  useRedemptionSaveMutation,
  useRedemptionUnsaveMutation,
} from '@graphql'
import { notifications } from '@mantine/notifications'

export function useSaveRedemption(id: string) {
  const [saveRedemption] = useRedemptionSaveMutation()
  const [unsaveRedemption] = useRedemptionUnsaveMutation()

  const handleSaveRedemption = () => {
    saveRedemption({
      variables: { redemptionId: id },
      optimisticResponse: {
        redemptionSave: {
          successful: true,
          result: {
            id: id,
            saved: true,
          },
          messages: [],
        },
      },
      onError: () =>
        notifications.show({
          title: 'Error',
          message: 'Failed to save redemption, please try again',
          color: 'red',
        }),
    })
  }

  const handleUnsaveRedemption = () => {
    unsaveRedemption({
      variables: { redemptionId: id },
      optimisticResponse: {
        redemptionUnsave: {
          successful: true,
          result: {
            id: id,
            saved: false,
          },
          messages: [],
        },
      },
      onError: () =>
        notifications.show({
          title: 'Error',
          message: 'Failed to unsave redemption, please try again',
          color: 'red',
        }),
    })
  }

  return { handleSaveRedemption, handleUnsaveRedemption }
}
