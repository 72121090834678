import {
  Redemption,
  RedemptionLimitRate,
  RedemptionLimitScope,
  RedemptionLimitType,
  SignedUrlKey,
} from '@graphql'
import { Box, Image, Skeleton, Stack } from '@mantine/core'
import { useHover } from '@mantine/hooks'

import redemptionPlaceholder from '@assets/redemption-placeholder-square.png'
import { GlobalLimitBadge } from '@components/limitBadges/GlobalLimitBadge'
import { IntervalLimitBadge } from '@components/limitBadges/IntervalLimitBadge'
import { MemberLimitBadge } from '@components/limitBadges/MemberLimitBadge'
import { ValidEndLimitBadge } from '@components/limitBadges/ValidEndLimitBadge'
import { cn } from '@util/utils'
import { useState } from 'react'
import { Link } from 'react-router-dom'

type RedemptionFeedImageProps = {
  redemption: Redemption
  isMobileViewport?: boolean
  showExpirationSection?: boolean
}

export const RedemptionFeedImage = ({
  redemption,
  showExpirationSection = true,
}: RedemptionFeedImageProps) => {
  const [imageHasLoaded, setImageHasLoaded] = useState(false)
  const [imageHasError, setImageHasError] = useState(false)
  const { hovered, ref } = useHover()

  const validEnd = redemption?.validEnd

  const limits = redemption?.limits?.limits

  const globalLimit = limits?.find(
    (limit) =>
      limit.limitType === RedemptionLimitType.Count &&
      limit.limitScope === RedemptionLimitScope.Global
  )

  const memberLimit = limits?.find(
    (limit) =>
      limit.limitType === RedemptionLimitType.Count &&
      limit.limitScope === RedemptionLimitScope.User
  )

  const intervalLimit = limits?.find(
    (limit) =>
      limit.limitType === RedemptionLimitType.RateLimit &&
      limit.limitScope === RedemptionLimitScope.User
  )

  const downloadUrls = redemption?.primaryMedia?.downloadUrls
  const thumbnailUrl = downloadUrls
    ? downloadUrls.find((url) => url.key === SignedUrlKey.Thumbnail)?.url
    : undefined

  const linkedProfile = redemption?.linkedProfile

  const shouldShowLinkedProfilePhoto =
    linkedProfile && linkedProfile.id !== redemption?.vendor?.id

  return (
    <Box
      h="100%"
      w="100%"
      pos="absolute"
      style={{ overflow: 'hidden' }}
      bg="swurple"
    >
      {thumbnailUrl && !imageHasError && (
        <>
          {!imageHasLoaded && (
            <Skeleton h="35vh" w="100%" style={{ zIndex: 1 }} radius={0} />
          )}
          {thumbnailUrl && (
            <Image
              src={thumbnailUrl}
              fit="cover"
              mih="100%"
              onLoad={() => setImageHasLoaded(true)}
              onError={() => setImageHasError(true)}
              className={cn(!imageHasLoaded && 'opacity-0')}
            />
          )}
        </>
      )}
      {(!thumbnailUrl || imageHasError) && (
        <>
          <Image
            src={redemptionPlaceholder}
            fit="cover"
            mih="100%"
            w="100%"
            h="100%"
            onLoad={() => setImageHasLoaded(true)}
            className={cn(!imageHasLoaded && 'opacity-0')}
          />
          {showExpirationSection && (
            <Stack pos="absolute" left={8} top={60}>
              {validEnd && <ValidEndLimitBadge redemptionValidEnd={validEnd} />}
              {globalLimit && <GlobalLimitBadge {...globalLimit} />}
              {memberLimit && <MemberLimitBadge {...memberLimit} />}
              {intervalLimit && (
                <IntervalLimitBadge
                  {...(intervalLimit as RedemptionLimitRate)}
                />
              )}
            </Stack>
          )}
        </>
      )}
      {shouldShowLinkedProfilePhoto && (
        <Link to={`/profile/${linkedProfile.displayName}`}>
          <Box ref={ref} pos="relative">
            <Image
              src={linkedProfile.profilePhotoUrl}
              alt="Linked profile"
              h={hovered ? 55 : 50}
              w={hovered ? 55 : 50}
              className="transition-width-height duration-100"
              pos="absolute"
              right={0}
              bottom={0}
              radius="xl"
              m={8}
              style={{ zIndex: 1 }}
            />
          </Box>
        </Link>
      )}
    </Box>
  )
}
