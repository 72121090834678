import { ApolloError } from '@apollo/client'
import { Badge } from '@capawesome/capacitor-badge'
import {
  ConversationListPreviewFragment,
  ConversationsEventDocument,
  useConversationsListQuery,
} from '@graphql'
import { useAuth } from '@hooks/useAuth'
import { createContext, useEffect, useMemo } from 'react'

export interface ConversationsContextType {
  conversations: Array<ConversationListPreviewFragment>
  conversationsLoading: boolean
  conversationsError: ApolloError | undefined
  unreadMessagesCount: number
  networkStatus: number
  refetch: () => Promise<any>
}

export const ConversationsContext = createContext<ConversationsContextType>({
  conversations: [],
  conversationsLoading: false,
  conversationsError: undefined,
  unreadMessagesCount: 0,
  networkStatus: 0,
  refetch: () => Promise.resolve(),
})

export function ConversationsContextProvider({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const { currentUser, onLogout } = useAuth()

  const {
    data: conversations,
    loading,
    error,
    subscribeToMore,
    networkStatus,
    refetch,
  } = useConversationsListQuery({
    onError: (err) => {
      console.error(
        '[Conversations Context] Retrieving Conversations List error: ',
        err
      )
      if (err.message === 'You must be logged in to execute this action') {
        onLogout(currentUser?.id, { isExpiredSession: true })
      }
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  useEffect(() => {
    // subscribe to more of the conversationsListQuery and update the list of conversations when
    // a new conversation update comes in
    const unsubscribe = subscribeToMore({
      document: ConversationsEventDocument,
      updateQuery: (prev, { subscriptionData }) => {
        // The typing automatically believes it'll be a Conversation in shape, not
        // A Conversation Event
        const data = subscriptionData.data as any
        if (!data) return prev

        const event = data.conversationsEvent as any
        if (!event) return prev

        switch (event.__typename) {
          case 'MessageEvent':
            if (event.messageEventType === 'MESSAGE_SENT') {
              if (!prev || !prev.conversations) return prev

              const conversationIndex = prev.conversations.findIndex(
                (conversation) =>
                  conversation.id === event.message.conversationPreview.id
              )
              const conversation = prev.conversations[conversationIndex]

              let returnVal = prev

              // If the conversation does not exist, add it to the top of the list
              if (!conversation) {
                returnVal = {
                  ...prev,
                  conversations: [
                    event.message.conversationPreview,
                    ...prev.conversations,
                  ],
                }
                // Otherwise, update the conversation preview
              } else {
                returnVal = {
                  ...prev,
                  conversations: [
                    {
                      ...conversation,
                      messagePreview:
                        event.message.conversationPreview.messagePreview,
                      unreadMessageCount:
                        event.message.conversationPreview.unreadMessageCount,
                    },
                    ...prev.conversations.slice(0, conversationIndex),
                    ...prev.conversations.slice(conversationIndex + 1),
                  ],
                }
              }

              return returnVal
            }
            return prev
          default:
            return prev
        }
      },
    })

    return () => {
      unsubscribe()
    }
  }, [subscribeToMore])

  const unreadMessageCount = useMemo(() => {
    return (
      conversations?.conversations?.reduce((acc, conversation) => {
        if (
          conversation.unreadMessageCount === null ||
          conversation.unreadMessageCount === undefined
        )
          return acc
        return acc + conversation.unreadMessageCount
      }, 0) || 0
    )
  }, [conversations?.conversations])

  useEffect(() => {
    Badge.isSupported()
      .then((supported) => {
        if (supported) {
          Badge.checkPermissions().then((permissions) => {
            if (permissions.display === 'granted') {
              Badge.set({ count: unreadMessageCount })
            }
          })
        }
      })
      .catch((err) => {
        console.error(
          '[Conversations Context] - Local badge unable to set: ',
          err
        )
      })
  }, [unreadMessageCount])

  return (
    <ConversationsContext.Provider
      value={{
        conversations:
          (conversations?.conversations as Array<ConversationListPreviewFragment>) ||
          [],
        conversationsLoading: loading,
        conversationsError: error,
        unreadMessagesCount: unreadMessageCount,
        networkStatus,
        refetch,
      }}
    >
      {children}
    </ConversationsContext.Provider>
  )
}
