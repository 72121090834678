import { faGlobe } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RedemptionLimitPartsFragment } from '@graphql'
import { Badge } from '@mantine/core'

export function GlobalLimitBadge(limit: RedemptionLimitPartsFragment) {
  const redeemLimit = limit.limit || 0
  const redeemRedeemed = limit.count || 0

  return (
    <Badge
      color={limit.blocking ? 'red' : 'swurple'}
      variant="filled"
      leftSection={<FontAwesomeIcon className="mb-1 h-4 w-4" icon={faGlobe} />}
      p={12}
      mx={12}
    >
      {redeemLimit - redeemRedeemed} of {redeemLimit} remaining
    </Badge>
  )
}
