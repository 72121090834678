import redemptionsPlaceholder from '@assets/redemptions-placeholder-square.png'
import { GlobalLimitBadge } from '@components/limitBadges/GlobalLimitBadge'
import { IntervalLimitBadge } from '@components/limitBadges/IntervalLimitBadge'
import { MemberLimitBadge } from '@components/limitBadges/MemberLimitBadge'
import { ValidEndLimitBadge } from '@components/limitBadges/ValidEndLimitBadge'
import { Skeleton } from '@components/skeleton/Skeleton'
import { faHeart as faHeartLight } from '@fortawesome/free-regular-svg-icons'
import {
  faClock,
  faHeart as faHeartSolid,
} from '@fortawesome/free-solid-svg-icons'
import { faCalendar, faGlobe, faUser } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  RedemptionLimitPartsFragment,
  RedemptionLimitRate,
  RedemptionLimitScope,
  RedemptionLimitType,
} from '@graphql'
import { Avatar, Box, Center, Image, Stack, Text } from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { cn } from '@util/utils'
import { useState } from 'react'
import { Link } from 'react-router-dom'

const avatarForLimit = (limit: RedemptionLimitPartsFragment) => {
  switch (limit.__typename) {
    case 'RedemptionLimitRate':
      return (
        <Avatar
          color="yellow"
          size="sm"
          key={`${limit.limitScope}-${limit.limitType}`}
        >
          <FontAwesomeIcon icon={faCalendar} />
        </Avatar>
      )

    case 'RedemptionLimitCount':
      return limit.limitScope == RedemptionLimitScope.User ? (
        <Avatar
          color="blue"
          size="sm"
          key={`${limit.limitScope}-${limit.limitType}`}
        >
          <FontAwesomeIcon icon={faUser} />
        </Avatar>
      ) : (
        <Avatar
          color="purple"
          size="sm"
          key={`${limit.limitScope}-${limit.limitType}`}
        >
          <FontAwesomeIcon icon={faGlobe} />
        </Avatar>
      )

    default:
      return <></>
  }
}

const RedemptionCardImage = ({
  hasLimits,
  isExpired,
  src,
  profilePhotoOrRedemptionsPlaceholder,
  validEnd,
  limits,
  imageMaxHeight,
}: {
  hasLimits?: boolean
  isExpired?: boolean
  src?: string
  profilePhotoOrRedemptionsPlaceholder: string
  validEnd: string | undefined
  limits: RedemptionLimitPartsFragment[]
  imageMaxHeight?: number
}) => {
  const { hovered, ref } = useHover()

  // The `validEnd` works as a limit, even though is not technically one.
  const totalLimitCount = limits.length + (validEnd ? 1 : 0)

  // We can display up to 3 avatars in total. If `validEnd` is present, we show 2 normal limits at max.
  const maxNormalLimitsToShow = validEnd ? 2 : 3

  return (
    <>
      <Avatar.Group
        pos="absolute"
        spacing={hovered && totalLimitCount > 1 ? 4 : 'sm'}
        top={0}
        left={0}
        m={6}
        ref={ref}
        className="transition-spacing duration-300"
      >
        {validEnd && (
          <Avatar color="cyan.6" size="sm">
            <FontAwesomeIcon icon={faClock} />
          </Avatar>
        )}

        {hasLimits &&
          limits
            .slice(0, maxNormalLimitsToShow)
            .map((limit) => avatarForLimit(limit))}

        {/* If we have more than 3 limits, show a "+" avatar */}
        {totalLimitCount > 3 && (
          <Avatar size="sm">+{totalLimitCount - 3}</Avatar>
        )}
      </Avatar.Group>

      {isExpired && (
        <Center
          pos="absolute"
          top={0}
          left={0}
          w="100%"
          h="100%"
          style={{ zIndex: 1 }}
        >
          <Box bg="swurple" className="-rotate-[15deg]" px={20}>
            <Text c="white" ta="center" fz="16px" visibleFrom="md">
              EXPIRED
            </Text>
            <Text c="white" ta="center" fz="12px" hiddenFrom="md">
              EXPIRED
            </Text>
          </Box>
        </Center>
      )}
      <Image
        fit="cover"
        radius="md"
        src={src}
        h={128}
        w={128}
        mah={imageMaxHeight ? imageMaxHeight : undefined}
        fallbackSrc={profilePhotoOrRedemptionsPlaceholder}
        visibleFrom="md"
        className={`${isExpired ? 'grayscale' : ''}`}
      />
      <Image
        fit="cover"
        radius="md"
        src={src}
        h={105}
        w={105}
        mah={imageMaxHeight ? imageMaxHeight : undefined}
        fallbackSrc={profilePhotoOrRedemptionsPlaceholder}
        hiddenFrom="md"
        className={`${isExpired ? 'grayscale' : ''}`}
      />
    </>
  )
}

const RedemptionDetailsImage = ({
  isExpired,
  src,
  srcSet,
  validEnd,
  globalLimit,
  memberLimit,
  intervalLimit,
  imageMaxHeight,
}: {
  isExpired?: boolean
  src?: string
  srcSet?: string
  validEnd?: string
  globalLimit?: RedemptionLimitPartsFragment
  memberLimit?: RedemptionLimitPartsFragment
  intervalLimit?: RedemptionLimitPartsFragment
  imageMaxHeight?: number
}) => {
  const [hasLoaded, setHasLoaded] = useState(false)
  const [hasError, setHasError] = useState(false)

  return (
    <>
      {!isExpired && (
        <>
          <Stack pos="absolute" left={8} top={8} mt={6} style={{ zIndex: 1 }}>
            {validEnd && <ValidEndLimitBadge redemptionValidEnd={validEnd} />}
            {globalLimit && <GlobalLimitBadge {...globalLimit} />}
            {memberLimit && <MemberLimitBadge {...memberLimit} />}
            {intervalLimit && (
              <IntervalLimitBadge {...(intervalLimit as RedemptionLimitRate)} />
            )}
          </Stack>
        </>
      )}

      {isExpired && (
        <Center
          pos="absolute"
          top={0}
          left={0}
          w="100%"
          h="100%"
          style={{ zIndex: 1 }}
        >
          <Box bg="swurple" className="-rotate-[15deg]" px={20}>
            <Text c="white" ta="center" fz="72px">
              EXPIRED
            </Text>
          </Box>
        </Center>
      )}

      {src && (
        <Box className="h-auto max-h-[200px] md:max-h-[300px]">
          {!hasLoaded && !hasError && (
            <Skeleton
              className="absolute left-0 top-0 h-full w-full rounded-md"
              style={{ zIndex: 1 }}
            />
          )}
          <Image
            src={src}
            w={500}
            h={200}
            mah={imageMaxHeight ? imageMaxHeight : undefined}
            srcSet={srcSet}
            onLoad={() => setHasLoaded(true)}
            onError={() => setHasError(true)}
            radius="md"
            fit="cover"
            className={cn(
              'block aspect-[500/300] h-auto max-h-[200px] bg-accent md:max-h-[300px]',
              !hasLoaded && 'opacity-0',
              isExpired && 'grayscale'
            )}
          />
        </Box>
      )}

      {(!src || hasError) && (
        <Box pos="relative" className="h-auto max-h-[200px] md:max-h-[300px]">
          <Image
            w={500}
            h={300}
            mah={imageMaxHeight ? imageMaxHeight : undefined}
            radius="md"
            fit="cover"
            className={cn(
              'block aspect-[500/300] h-auto max-h-[200px] md:max-h-[300px]',
              !isExpired && 'bg-accent',
              isExpired && 'grayscale'
            )}
            src={redemptionsPlaceholder}
          />
        </Box>
      )}
    </>
  )
}

export const RedemptionImage = ({
  isExpired,
  src,
  srcSet,
  ownerProfileId,
  profilePhotoUrl,
  isForCard = false,
  limits,
  validEnd,
  linkedProfileId,
  linkedProfilePhotoUrl,
  linkedProfileDisplayName,
  showSaveSection = false,
  saved,
  handleSaveRedemption,
  handleUnsaveRedemption,
  hasLimits,
  imageMaxHeight,
}: {
  isExpired: boolean | undefined
  src: string | undefined
  srcSet: string | undefined
  ownerProfileId?: string
  profilePhotoUrl?: string
  isForCard?: boolean
  limits?: RedemptionLimitPartsFragment[] | undefined | null
  validEnd?: string | undefined | null
  linkedProfileId?: string | null
  linkedProfilePhotoUrl?: string | null
  linkedProfileDisplayName?: string | null
  showSaveSection?: boolean
  saved?: boolean
  handleSaveRedemption?: () => void
  handleUnsaveRedemption?: () => void
  hasLimits?: boolean
  imageMaxHeight?: number
}) => {
  const isCommunityProfilePhotoDefault =
    typeof profilePhotoUrl === 'string' &&
    profilePhotoUrl.includes('default/avatar')

  const profilePhotoOrRedemptionsPlaceholder = isCommunityProfilePhotoDefault
    ? redemptionsPlaceholder
    : profilePhotoUrl || ''

  const { hovered, ref } = useHover()

  const globalLimit = limits?.find(
    (limit) =>
      limit.limitType === RedemptionLimitType.Count &&
      limit.limitScope === RedemptionLimitScope.Global
  )

  const memberLimit = limits?.find(
    (limit) =>
      limit.limitType === RedemptionLimitType.Count &&
      limit.limitScope === RedemptionLimitScope.User
  )

  const intervalLimit = limits?.find(
    (limit) =>
      limit.limitType === RedemptionLimitType.RateLimit &&
      limit.limitScope === RedemptionLimitScope.User
  )

  const shouldShowLinkedProfilePhoto =
    linkedProfileId && linkedProfileId !== ownerProfileId

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    saved ? handleUnsaveRedemption?.() : handleSaveRedemption?.()
  }

  const appliedLimits = [globalLimit, intervalLimit].filter(
    (limit) => limit !== undefined && limit !== null
  )

  return (
    <>
      {isForCard ? (
        <RedemptionCardImage
          hasLimits={hasLimits}
          limits={appliedLimits as RedemptionLimitPartsFragment[]}
          validEnd={validEnd || undefined}
          isExpired={isExpired}
          src={src}
          profilePhotoOrRedemptionsPlaceholder={
            profilePhotoOrRedemptionsPlaceholder
          }
          imageMaxHeight={imageMaxHeight}
        />
      ) : (
        <RedemptionDetailsImage
          isExpired={isExpired}
          src={src}
          srcSet={srcSet}
          validEnd={validEnd || undefined}
          globalLimit={globalLimit}
          memberLimit={memberLimit}
          intervalLimit={intervalLimit}
          imageMaxHeight={imageMaxHeight}
        />
      )}

      {showSaveSection && (
        <>
          <Box
            pos="absolute"
            top={0}
            right={0}
            style={{
              borderLeft: '50px solid transparent',
              borderTop: '50px solid rgba(0,0,0,0.2)',
              borderTopRightRadius: '10px',
              zIndex: 1,
            }}
          />
          <Box
            pos="absolute"
            top={0}
            right={0}
            px={8}
            py={5}
            style={{ zIndex: 1 }}
            onClick={handleClick}
          >
            {saved ? (
              <FontAwesomeIcon
                icon={faHeartSolid}
                color="white"
                size="sm"
                className="hover:scale-110"
              />
            ) : (
              <FontAwesomeIcon
                icon={faHeartLight}
                color="white"
                size="sm"
                className="hover:scale-110"
              />
            )}
          </Box>
        </>
      )}

      {shouldShowLinkedProfilePhoto && (
        <Link to={`/profile/${linkedProfileDisplayName}`}>
          <Box ref={ref}>
            <Image
              src={linkedProfilePhotoUrl}
              alt="Linked profile"
              h={hovered ? 55 : 50}
              w={hovered ? 55 : 50}
              className="transition-width-height duration-100"
              pos="absolute"
              right={0}
              bottom={0}
              radius="xl"
              m={8}
              style={{ zIndex: 1 }}
            />
          </Box>
        </Link>
      )}
    </>
  )
}
