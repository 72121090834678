import redemptionPlaceholder from '@assets/redemption-placeholder-square.png'
import {
  InteractionClick,
  InteractionPoll,
  InteractionReply,
  MediaType,
  Message,
  MessagePreviewMediaPointerPartsFragment,
  useFeedGetSingleInteractionQuery,
} from '@graphql'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { Box, Divider, Loader, Stack, Text } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IMGIX_FEED_CONSTANTS } from '@pages/feed/constants/imgixFeed.constants'
import { generateImgixOptions } from '@util/imgixUtils'
import { SEMI_BOLD } from '@util/utils'
import { uniqueId } from 'lodash-es'
import { useCallback, useState } from 'react'
import { FeedCardFooter } from '../FeedCardFooter'
import { FeedCardShowMore } from '../FeedCardShowMore'
import { FeedImage } from '../FeedImage'
import { Overlay } from '../Overlay'
import { ClickInteraction } from './Click.interaction'
import { FollowUpInteraction } from './FollowUp.interaction'

export function ClickInteractionContainer({
  id,
  scrollToNextItem,
}: {
  id: string
  scrollToNextItem: () => void
}) {
  const isMobileViewport = useIsMobileViewport()
  const [containerUUID] = useState<string>(uniqueId('interactionContainer-'))
  const [followUpOpened, { open: openFollowUp, close: closeFollowUp }] =
    useDisclosure(false)

  const { data, loading } = useFeedGetSingleInteractionQuery({
    variables: {
      id,
      imgixOpts: generateImgixOptions({
        auto: 'compress',
        ...IMGIX_FEED_CONSTANTS,
      }),
    },
    fetchPolicy: 'network-only',
  })

  const clickInteraction = data?.oneInteraction as InteractionClick

  const message = (clickInteraction as any)?.clickMessage as Message

  const displayName = message?.author?.displayName
  const authorPhoto = message?.author?.profilePictureUrl || undefined
  const body = message?.body
  const mediaAttachment = message?.attachments?.[0]
  const imageAttachment =
    mediaAttachment?.mediaType === MediaType.Image
      ? (mediaAttachment as MessagePreviewMediaPointerPartsFragment)
      : undefined
  const imageUrl = imageAttachment?.thumbnail?.url || redemptionPlaceholder

  const handleCompletedInteraction = useCallback(
    (followUpId: string | undefined) => {
      if (followUpId) {
        // Handle follow-up interaction
        openFollowUp()
      } else {
        closeFollowUp()
        scrollToNextItem()
      }
    },
    [closeFollowUp, openFollowUp, scrollToNextItem]
  )

  return (
    <>
      {loading ? (
        <Loader color="swurple" size="xl" variant="dots" />
      ) : (
        <>
          <Box
            h="100%"
            w="100%"
            pos="relative"
            bg="swurple"
            c="white"
            id={containerUUID}
          >
            <Overlay
              avatarImageUrl={authorPhoto}
              vendorDisplayName={displayName}
            />
            <FeedImage
              primaryMedia={mediaAttachment}
              mediaUrl={imageUrl}
              linkedProfile={message?.author}
              vendorId={message?.author?.id}
            />
            <Stack
              h="100%"
              w="100%"
              gap={0}
              justify="center"
              align="center"
              style={{ paddingTop: `var(--app-shell-header-height)` }}
            >
              <FeedCardFooter>
                <Text fw={SEMI_BOLD} size={isMobileViewport ? 'lg' : 'xl'}>
                  {displayName}
                </Text>
                <Divider />
                <FeedCardShowMore>
                  <Text
                    style={{ whiteSpace: 'pre-line' }}
                    size={isMobileViewport ? 'md' : 'lg'}
                  >
                    {body}
                  </Text>
                </FeedCardShowMore>
                <Box pt={8}>
                  <ClickInteraction
                    id={id}
                    interaction={clickInteraction}
                    completedInteraction={(followUpId: string | undefined) =>
                      handleCompletedInteraction(followUpId)
                    }
                  />
                </Box>
              </FeedCardFooter>
            </Stack>
          </Box>
          <FollowUpInteraction
            followUpOpened={followUpOpened}
            containerUUID={containerUUID}
            followUpInteraction={
              (clickInteraction?.followUpInteraction as
                | InteractionPoll
                | InteractionReply
                | InteractionClick) || undefined
            }
            closeFollowUp={closeFollowUp}
            handleCompletedInteraction={handleCompletedInteraction}
          />
        </>
      )}
    </>
  )
}
