import { useFeedItemSeenMutation } from '@graphql'
import { useIsMobileViewport } from '@hooks/useIsMobileViewport'
import { Box, Flex } from '@mantine/core'
import { RefObject, forwardRef, useEffect, useRef, useState } from 'react'

interface FeedCardProps {
  id: string
  itemId: string
  children: React.ReactNode
  handleUpdateLastSeenItemId: () => void
}

export const FeedCard = forwardRef<HTMLDivElement, FeedCardProps>(
  ({ id, itemId, children, handleUpdateLastSeenItemId }, ref) => {
    const [feedItemSeenState, setFeedItemSeenState] = useState<{
      seen: boolean
      startTime: Date | undefined
      reported: boolean
    }>({
      seen: false,
      startTime: undefined,
      reported: false,
    })
    const isMobileViewport = useIsMobileViewport()

    const elementRef = useRef<HTMLDivElement>(null)
    const inViewport = useIntersectionObserver(elementRef, {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.7, // Trigger when 10% of the element is visible
    })

    const [seenFeedItem] = useFeedItemSeenMutation()

    useEffect(
      function reportIfSeen() {
        // If the item is in the viewport, update the last seen item ID so if the user leaves the page and then come back, they will see the correct feed item
        if (inViewport) {
          handleUpdateLastSeenItemId()
        }

        if (inViewport && !feedItemSeenState.seen) {
          setFeedItemSeenState({
            seen: true,
            startTime: new Date(),
            reported: false,
          })
        } else if (
          !inViewport &&
          feedItemSeenState.seen &&
          !feedItemSeenState.reported &&
          feedItemSeenState.startTime
        ) {
          const deltaMs =
            new Date().getTime() - feedItemSeenState.startTime.getTime()

          if (deltaMs < 1000) return // Short circuit if less than 1 seconds

          seenFeedItem({
            variables: {
              feedItemSeenId: itemId,
              deltaMs:
                new Date().getTime() - feedItemSeenState.startTime.getTime(),
            },
          })

          setFeedItemSeenState({
            seen: true,
            startTime: feedItemSeenState.startTime,
            reported: true,
          })
        }
      },
      [
        feedItemSeenState.reported,
        feedItemSeenState.seen,
        feedItemSeenState.startTime,
        itemId,
        inViewport,
        seenFeedItem,
        handleUpdateLastSeenItemId,
      ]
    )

    if (!isMobileViewport) {
      return (
        <Flex
          id={id}
          ref={ref}
          style={{
            scrollSnapAlign: 'start',
          }}
          justify="center"
          pos="relative"
          h="100%"
          w={isMobileViewport ? '100%' : undefined}
        >
          <Box
            ref={elementRef}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              height: '100%',
              overflow: 'hidden',
              aspectRatio: '9 / 16',
              border: '4px solid #000',
            }}
            className="rounded-xl border-4 border-black shadow-xl"
          >
            {children}
          </Box>
        </Flex>
      )
    }

    return (
      <Box
        id={id}
        ref={ref}
        style={{
          height: 'calc(100vh - var(--app-shell-footer-height))',
          scrollSnapAlign: 'start',
          position: 'relative',
        }}
        w="100%"
      >
        <Box
          ref={elementRef}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          {children}
        </Box>
      </Box>
    )
  }
)

interface IntersectionObserverArgs extends IntersectionObserverInit {
  root?: Element | Document | null
  rootMargin?: string
  threshold?: number | number[]
}

const useIntersectionObserver = (
  elementRef: RefObject<Element>,
  options: IntersectionObserverArgs
): boolean => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting)
    }, options)

    const currentElement = elementRef.current
    if (currentElement) {
      observer.observe(currentElement)
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement)
      }
    }
  }, [elementRef, options])

  return isVisible
}
