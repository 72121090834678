import {
  ProfileType,
  Redemption,
  RedemptionMediaPreviewPartsFragment,
} from '@graphql'
import { useAuth } from '@hooks/useAuth'
import { Box, Card, Divider } from '@mantine/core'
import { RedemptionHorizontalCard } from '@pages/redemptions/components/RedemptionHorizontalCard'
import { isRedemptionExpired } from '@util/redemptionsUtils'
import { Link } from 'react-router-dom'

export type RedemptionsListProps = {
  avatarImageUrl: string
  name: string
  redemptions: Array<Redemption>
}

export function VendorProfileRedemptionsList({
  avatarImageUrl,
  name,
  redemptions,
}: RedemptionsListProps) {
  return (
    <Card radius="lg" pb={0} w="100%">
      {redemptions.map((redemption, index) => {
        if (!redemption.active) return
        return (
          <Box
            key={redemption.id}
            className="mb-4 flex w-full flex-col justify-center"
            maw={600}
          >
            <Link
              color="black"
              className="text-left hover:shadow-md"
              to={`/redemptions/offers/${redemption.id}`}
            >
              <RedemptionPreview
                avatarImageUrl={avatarImageUrl}
                name={name}
                redemption={redemption}
              />
            </Link>
            {index < redemptions.length - 1 && <Divider mt="14" />}
          </Box>
        )
      })}
    </Card>
  )
}

type RedemptionProps = {
  avatarImageUrl: string
  name: string
  redemption: Redemption
}

function RedemptionPreview({
  avatarImageUrl,
  name,
  redemption,
}: RedemptionProps) {
  const { currentUser } = useAuth()

  const isCurrentUser = !!currentUser

  const isVendor = currentUser?.profileType === ProfileType.Community

  if (!redemption) {
    return null
  }

  return (
    <RedemptionHorizontalCard
      id={redemption.id}
      name={redemption.name}
      description={redemption.description}
      community={{
        profilePhotoUrl: avatarImageUrl,
        communityName: name,
      }}
      price={{ amount: redemption.price?.amount || 0 }}
      redemptionMedia={redemption as RedemptionMediaPreviewPartsFragment}
      isNonProfit={redemption.isNonProfit}
      donationProgress={redemption.donationProgress}
      linkedProfile={redemption.linkedProfile}
      isExpired={isRedemptionExpired(redemption as Redemption)}
      ownerProfileId={redemption.community.id}
      hasLimits={!!redemption.limits?.limits?.length}
      showSaveSection={isCurrentUser && !isVendor}
      saved={!!redemption.saved}
    />
  )
}
