import { ImgixUrlParamsArFixed } from '@util/imgixUtils'

export const IMGIX_FEED_CONSTANTS = {
  ar: '9:16',
  w: 900,
  h: 1600,

  // TODO: Discuss the below options with the team
  // fit: 'fill',
  // fill: 'blur',
} as ImgixUrlParamsArFixed
