import { RedemptionFiltersProvider } from '@context/redemptionFiltersContext'
import { Center } from '@mantine/core'
import { ErrorBoundary } from 'react-error-boundary'
import { RequireVendor } from '../../routes/guards/RequireVendor'

export const PublicRedemptionsRoutes = [
  {
    path: 'redemptions/offers/:redemptionId',
    async lazy() {
      const { PublicRedemptionsPage } = await import('./PublicRedemptions.page')
      return {
        Component: PublicRedemptionsPage,
      }
    },
  },
]

export const MemberRedemptionsRoutes = [
  {
    path: 'redemptions/vendors',
    async lazy() {
      const { RedemptionVendors } = await import('./RedemptionVendors.page')
      return {
        element: (
          <ErrorBoundary fallback={<Center>Something went wrong</Center>}>
            <RedemptionVendors />
          </ErrorBoundary>
        ),
      }
    },
    children: [
      {
        path: '',
        async lazy() {
          const { EmptyVendorScreen } = await import(
            './screens/EmptyVendor.screen'
          )
          return {
            Component: EmptyVendorScreen,
          }
        },
      },
      {
        path: ':displayName',
        async lazy() {
          const { VendorRedemptionsProfileScreen } = await import(
            './screens/VendorRedemptionsProfile.screen'
          )
          return {
            Component: VendorRedemptionsProfileScreen,
          }
        },
      },
    ],
  },
  {
    path: 'redemptions/saved',
    async lazy() {
      const { SavedRedemptionsPage } = await import('./SavedRedemptions.page')
      return {
        element: (
          <ErrorBoundary fallback={<Center>Something went wrong</Center>}>
            <RedemptionFiltersProvider>
              <SavedRedemptionsPage />
            </RedemptionFiltersProvider>
          </ErrorBoundary>
        ),
      }
    },
    children: [
      {
        path: '',
        async lazy() {
          const { EmptyRedemptionScreen } = await import(
            './screens/EmptyRedemption.screen'
          )
          return {
            Component: EmptyRedemptionScreen,
          }
        },
      },
      {
        path: 'offers',
        async lazy() {
          const { EmptyRedemptionScreen } = await import(
            './screens/EmptyRedemption.screen'
          )
          return {
            Component: EmptyRedemptionScreen,
          }
        },
      },
      {
        path: 'offers/:redemptionId',
        async lazy() {
          const { RedemptionsScreen } = await import(
            './screens/Redemptions.screen'
          )
          return {
            Component: RedemptionsScreen,
          }
        },
      },
    ],
  },
  {
    path: 'redemptions/history',
    async lazy() {
      const { HistoryRedemptionsPage } = await import(
        './HistoryRedemptions.page'
      )
      return {
        element: (
          <ErrorBoundary fallback={<Center>Something went wrong</Center>}>
            <HistoryRedemptionsPage />
          </ErrorBoundary>
        ),
      }
    },
    children: [
      {
        path: '',
        async lazy() {
          const { EmptyRedemptionScreen } = await import(
            './screens/EmptyRedemption.screen'
          )
          return {
            Component: EmptyRedemptionScreen,
          }
        },
      },
      {
        path: 'vendors',
        async lazy() {
          const { EmptyVendorScreen } = await import(
            './screens/EmptyVendor.screen'
          )
          return {
            Component: EmptyVendorScreen,
          }
        },
      },
      {
        path: 'vendors/:displayName',
        async lazy() {
          const { VendorRedemptionsProfileScreen } = await import(
            './screens/VendorRedemptionsProfile.screen'
          )
          return {
            Component: VendorRedemptionsProfileScreen,
          }
        },
      },
      {
        path: ':redemptionId',
        async lazy() {
          const { RedemptionsScreen } = await import(
            './screens/Redemptions.screen'
          )
          return {
            Component: RedemptionsScreen,
          }
        },
      },
    ],
  },
  {
    path: 'redemptions',
    async lazy() {
      const { RedemptionsPage } = await import('./Redemptions.page')
      return {
        element: (
          <ErrorBoundary fallback={<Center>Something went wrong</Center>}>
            <RedemptionFiltersProvider>
              <RedemptionsPage />
            </RedemptionFiltersProvider>
          </ErrorBoundary>
        ),
      }
    },
    children: [
      {
        path: '',
        async lazy() {
          const { EmptyRedemptionScreen } = await import(
            './screens/EmptyRedemption.screen'
          )
          return {
            Component: EmptyRedemptionScreen,
          }
        },
      },
      {
        path: 'offers',
        async lazy() {
          const { EmptyRedemptionScreen } = await import(
            './screens/EmptyRedemption.screen'
          )
          return {
            Component: EmptyRedemptionScreen,
          }
        },
      },
      {
        path: 'offers/:redemptionId',
        async lazy() {
          const { RedemptionsScreen } = await import(
            './screens/Redemptions.screen'
          )
          return {
            Component: RedemptionsScreen,
          }
        },
      },
    ],
  },
]

export const VendorRedemptionsRoutes = [
  {
    path: 'redemptions',
    async lazy() {
      const { VendorRedemptionsPage } = await import('./VendorRedemptions.page')
      return {
        element: (
          <ErrorBoundary fallback={<Center>Something went wrong</Center>}>
            <RequireVendor>
              <VendorRedemptionsPage />
            </RequireVendor>
          </ErrorBoundary>
        ),
      }
    },
    children: [
      {
        path: '',
        async lazy() {
          const { LiveVendorRedemptionsScreen } = await import(
            './screens/vendor/LiveVendorRedemptions.screen'
          )
          return {
            Component: LiveVendorRedemptionsScreen,
          }
        },
      },
      {
        path: 'drafts',
        async lazy() {
          const { DraftVendorRedemptionsScreen } = await import(
            './screens/vendor/DraftVendorRedemptions.screen'
          )
          return {
            Component: DraftVendorRedemptionsScreen,
          }
        },
      },
      {
        path: 'archived',
        async lazy() {
          const { ArchivedVendorRedemptionsScreen } = await import(
            './screens/vendor/ArchivedVendorRedemptions.screen'
          )
          return {
            Component: ArchivedVendorRedemptionsScreen,
          }
        },
      },
      {
        path: 'edit/:redemptionId',
        async lazy() {
          const { EditRedemptionScreen } = await import(
            './screens/vendor/EditRedemption.screen'
          )
          return {
            Component: EditRedemptionScreen,
          }
        },
      },
      {
        path: ':redemptionId',
        async lazy() {
          const { VendorRedemptionDetailsScreen } = await import(
            './screens/vendor/VendorRedemptionDetails.screen'
          )
          return {
            Component: VendorRedemptionDetailsScreen,
          }
        },
      },
    ],
  },
]
