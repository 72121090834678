import { InputLabelSectionHeader } from '@components/InputLabelSectionHeader/InputLabelSectionHeader'
import { BroadcastTarget, Location } from '@graphql'
import {
  Box,
  Card,
  Group,
  Select,
  Stack,
  Switch,
  Text,
  Tooltip,
  Transition,
} from '@mantine/core'
import { kmToMiles, milesToKm } from '@util/utils'
import { useEffect, useState } from 'react'

type BroadcastTargetAudienceProps = {
  profilePrimaryLocation: Location | null | undefined
  setRadiusMiles: React.Dispatch<React.SetStateAction<number | null>>
  formFieldOnChange: any
  broadcastTarget: BroadcastTarget | undefined
}

export const BroadcastTargetAudience = ({
  // The primary location of the user.
  profilePrimaryLocation,
  // The radius miles of the broadcast targeting. We use a `state` in the parent component
  // in order to refetch the `targeted members` based on the provided radius.
  setRadiusMiles,
  // The `onChange` function of the form for the `BroadcastTarget` form field.
  formFieldOnChange,
  // The current `BroadcastTarget` value of the form.
  broadcastTarget,
}: BroadcastTargetAudienceProps) => {
  const [showLocalRestrictions, setShowLocalRestrictions] = useState(false)

  useEffect(() => {
    if (broadcastTarget?.allFollowers == false) {
      setShowLocalRestrictions(true)
      setRadiusMiles(broadcastTarget?.radiusKm || null)
    }
    // We only want to trigger this effect when the `allFollowers` value changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcastTarget?.allFollowers])

  const hasPrimaryLocation = !!profilePrimaryLocation

  const handleLocalRestrictionsChange = () => {
    setShowLocalRestrictions(!showLocalRestrictions)
    // If we are disabling the location restrictions section, then we reset the `broadcastTarget` to the default state.
    if (showLocalRestrictions) {
      formFieldOnChange({
        allFollowers: true,
        radiusKm: null,
        usePrimaryLocation: false,
      })
      setRadiusMiles(null)
    }
  }

  const handleMilesChange = (miles: string | null) => {
    // Mantine only allows `string` values on the `select` component,
    // so we need to convert that into a number.
    const milesAsNumber = Number(miles || 0)

    setRadiusMiles(milesAsNumber)
    formFieldOnChange({
      allFollowers: false,
      radiusKm: milesToKm(milesAsNumber),
      usePrimaryLocation: true,
    })
  }

  return (
    <Box>
      <InputLabelSectionHeader>Target audience</InputLabelSectionHeader>
      <Tooltip
        position="top-start"
        label="You must have a primary location for targeting members"
        disabled={hasPrimaryLocation}
      >
        <Switch
          checked={showLocalRestrictions}
          disabled={!hasPrimaryLocation}
          label="Location restrictions"
          onChange={handleLocalRestrictionsChange}
          mt={12}
        />
      </Tooltip>
      <Transition transition="fade" mounted={showLocalRestrictions}>
        {(styles) => (
          <Card style={styles} radius="md" withBorder ml={40} mt={12} p={16}>
            <Stack gap={12}>
              <Text size="sm">Only send to followers</Text>
              <Card radius="md" withBorder p={8} maw={250}>
                <Group gap={8}>
                  <Text size="sm">Within</Text>
                  <Box maw={120}>
                    <Select
                      data={[
                        '5',
                        '10',
                        '25',
                        '100',
                        '200',
                        '500',
                        '1000',
                        '2000',
                        '5000',
                        '10000',
                        '20000',
                        '50000',
                        '100000',
                      ]}
                      value={kmToMiles(
                        broadcastTarget?.radiusKm || 0
                      ).toFixed()}
                      withCheckIcon={false}
                      allowDeselect={false}
                      onChange={(value) => handleMilesChange(value)}
                    />
                  </Box>
                  <Text>miles</Text>
                </Group>
              </Card>
              <Card p={8} maw={250} radius="md" withBorder>
                <Text>
                  {profilePrimaryLocation?.title},{' '}
                  {profilePrimaryLocation?.address}
                </Text>
              </Card>
            </Stack>
          </Card>
        )}
      </Transition>
    </Box>
  )
}
